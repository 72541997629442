<template>
  <b-container fluid class="content">
    <!-------------------------------------- PO主頁 -------------------------------------->
    <div v-if="currpage == 'list'">
      <!-------------------------------------- PO主頁 上半部分 -------------------------------------->
      <b-card>
        <b-form>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <b-input-group :prepend="$t('Start Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_start_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('End Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_end_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <el-select
                class="el-dropdown"
                v-model="cur_date_type"
                :placeholder="$t('Date Type') + ' : '"
              >
                <el-option v-for="data_type in data_type_list"
                          :key="data_type.value"
                          :label="$t(data_type.label)"
                          :value="data_type.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                class="el-dropdown"
                v-model="cur_vendor"
                :placeholder="$t('Vendor') + ' : '"
              >
                <el-option v-for="vendor in vendor_cname_list"
                          :key="vendor.IDn"
                          :label="vendor.CompanyName"
                          :value="vendor.IDn"
                          @click.native="find_vendor_id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <b-input-group prepend="PO # : ">
                <b-form-input v-model="cur_order_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group prepend="Receive # : ">
                <b-form-input v-model="cur_receive_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6" v-if="false">
              <b-input-group prepend="Invoice # : ">
                <b-form-input v-model="cur_invoice_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Remarks') + ' : ' ">
                <b-form-input v-model="cur_remarks"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <SearchAutocomplete
                :url="apiBaseUrl + '/Web/product/search_allforlist'"
                :placeholdertxt="$t('PLU/Barcode/Name') + ' : '"
                :key="componentKey_SearchAutocomplete"
                :initString="cur_product"
                :whereFrom="'RE'"
                :selected="selected_SearchAutocomplete"
                @input="set_cur_product_name"
                @keyword="reset_cur_product_idn"
              ></SearchAutocomplete>
            </el-col>
            <el-col :span="6" v-if="false">
              <b-input-group :prepend="$t('Sale') + ' : '">
                <select class="form-control" v-model="cur_user_idn">
                  <option value="">{{ $t("Select Sale") }}</option>
                  <option
                    v-for="sales in sales_list"
                    :key="sales.IDn"
                    :value="sales.IDn"
                  >
                    {{ sales.UserName }}
                  </option>
                </select>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <el-select
                class="el-dropdown"
                v-model="cur_LocationID"
                :placeholder="$t('Store') + ' : '"
              >
                <el-option v-for="store in store_list"
                          :key="store.LocationID"
                          :label="store.LocationID"
                          :value="store.LocationID">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" class="text-center">
              <el-button
                type="primary"
                class="mt-2"
                icon="el-icon-search"
                size="small"
                @click="get_lists"
                >{{ $t("Search") }}</el-button
              >
            </el-col>
          </el-row>
        </b-form>
      </b-card>
      <!-------------------------------------- PO主頁 下半部分 -------------------------------------->
      <b-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-------------------------------------- PO主頁 下半部分 Pending tab-------------------------------------->
          <el-tab-pane :label="$t('Pending')" name="Pending">
            <el-skeleton :loading="loadingTabContent" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 300px;"
                />
              </template>
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="cur_po_list"
              >
                <el-table-column
                  label="OrderNo"
                  prop="OrderID"
                  class="p-0"
                  min-width="60px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ row.OrderID }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="OrderDate"
                  class="p-0"
                  min-width="70px"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">
                      {{ $t("Order Date") }}
                    </div>
                  </template>
                  <template v-slot="{ row }">
                    <div class="w-100 p-0 text-center">
                      {{ row.CreateDate.substr(0,16) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Vendor" class="p-0" min-width="100px" align="center" >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Vendor") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <span style="white-space:pre-wrap;">
                    {{
                      row.PurchaseFOtherName
                        ? row.PurchaseFName + " (" + row.PurchaseFOtherName + ")" 
                        : row.PurchaseFName
                    }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Total"
                  class="p-0"
                  align="right"
                  min-width="50px"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                  </template>
                  <template v-slot="{ row }"> 
                    <span style="padding-right: 10px">
                    {{ "$&ensp;" + row.TotalAmount  }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Store"
                  prop="ComTitle"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="BySales"
                  prop="CreateByName"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                  </template>
                </el-table-column>
                <el-table-column class="p-0" min-width="70px" align="center" v-if="hasUtilityAuth('ReceivingEdit')">
                  <template v-slot="{ row }">
                    <el-button class="btn-inner--icon"
                      type="success"
                      size="mini"
                      plain
                      @click="edit_po(row, 'Pending')"
                      v-acl:ReceivingEdit.enable.show>
                      <i class="el-icon-folder-opened" style="font-size: 1.25rem"></i>{{ "&nbsp;&nbsp;Open" }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-skeleton>
          </el-tab-pane>
          <!-------------------------------------- PO主頁 下半部分 Complete tab-------------------------------------->
          <el-tab-pane :label="$t('Complete')" name="Complete">
            <el-skeleton :loading="loadingTabContent" animated>
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 100%; height: 300px;"
                />
              </template>
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="cur_po_list"
              >
                <el-table-column
                  label="OrderNo"
                  prop="OrderID"
                  class="p-0"
                  min-width="60px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ row.OrderID }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="OrderDate"
                  class="p-0"
                  min-width="70px"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">
                      {{ $t("Order Date") }}
                    </div>
                  </template>
                  <template v-slot="{ row }">
                    <div class="w-100 p-0 text-center">
                      {{ row.CreateDate.substr(0,16) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Vendor" class="p-0" min-width="100px" align="center" >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Vendor") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <span style="white-space:pre-wrap;">
                    {{
                      row.PurchaseFOtherName
                        ? row.PurchaseFName + " (" + row.PurchaseFOtherName + ")" 
                        : row.PurchaseFName
                    }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Total"
                  class="p-0"
                  align="right"
                  min-width="50px"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                  </template>
                  <template v-slot="{ row }"> 
                    <span style="padding-right: 10px">
                    {{ "$&ensp;" + row.TotalAmount  }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Store"
                  prop="ComTitle"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="BySales"
                  prop="CreateByName"
                  class="p-0"
                  min-width="100px"
                  align="center"
                >
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                  </template>
                </el-table-column>
                <el-table-column class="p-0" min-width="70px" align="center" v-if="hasUtilityAuth('ReceivingEdit')">
                  <template v-slot="{ row }">
                    <el-button class="btn-inner--icon"
                      type="success"
                      size="mini"
                      plain
                      @click="edit_po(row, 'Complete')"
                      v-acl:ReceivingEdit.enable.show>
                      <i class="el-icon-folder-opened" style="font-size: 1.25rem"></i>{{ "&nbsp;&nbsp;Open" }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-skeleton>
          </el-tab-pane>
        </el-tabs>
      </b-card>
    </div>

    <!-------------------------------------- PO訂單詳情分頁 -------------------------------------->
    <div class="po-edit" v-if="currpage == 'edit'">
      <!-------------------------------------- PO訂單詳情分頁 Header -------------------------------------->
      <el-row v-if="cur_po">
        <el-col>
          <div class="so_title p-2">
            <h1>{{ cur_po.OrderID }} {{ cur_po.Status }}</h1>
          </div>
        </el-col>
      </el-row>
      <!-------------------------------------- activeCollapse1 -------------------------------------->
      <el-collapse v-model="activeCollapse1">
        <!-------------------------------------- 地址訊息 -------------------------------------->
        <el-collapse-item
          class="collapseheader addressinfo"
          :title="$t('Address Info')"
          name="AddressInfo"
          @click.native="calculate_el_select_width2()"
        >
          <el-row :gutter="10">
            <!-------------------------------------- 選擇供應商 -------------------------------------->
            <el-col :span="8">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row style="padding: 3px 0px">
                    <el-col :span="12">
                      <div class="so_card_title" style="padding-top: 5px">{{ $t("Vendor") }}</div>
                    </el-col>
                  </el-row>
                </div>
                <!----------------------------------- Vendor form @else ----------------------------------->
                <section>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Name") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFName }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Other Name") + " : " }}
                    </el-col>
                    <el-col :span="16" >
                      {{ cur_po.PurchaseFOtherName }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Contact") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFContact }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Tel") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFTEL }}
                      {{ cur_po.PurchaseFExt ? "- " + cur_po.PurchaseFExt : "" }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t("Fax") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.PurchaseFFAX }}
                    </el-col>
                  </el-row>
                  <div v-if="cur_po.PurchaseFAddressIDn != 0">
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Address") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <div>{{ cur_po.PurchaseFAddress1 }}</div>
                          <div>{{ cur_po.PurchaseFAddress2 }}</div>
                          <div>
                            {{ cur_po.PurchaseFCity }},
                            {{ cur_po.PurchaseFProvinceIDn > 0 ? province_list[cur_po.PurchaseFProvinceIDn].Province : "" }}
                            {{ cur_po.PurchaseFCountryIDn > 0 ? country_list[cur_po.PurchaseFCountryIDn].Country : "" }}
                            {{ cur_po.PurchaseFZIP }}
                          </div>
                      </el-col>
                    </el-row>
                  </div>
                </section>
              </el-card>
            </el-col>
            <!-------------------------------------- 選擇Billing Address-------------------------------------->
            <el-col :span="8">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row style="padding: 3px 0px">
                    <el-col :span="12">
                      <div class="so_card_title" style="padding-top: 5px">
                        {{ $t("Billing Address") }}
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <!----------------------------------- Billing address form @else ----------------------------------->
                  <section>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Name") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        {{ cur_po.ComTitle }}
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Contact") + " : " }}
                      </el-col>
                      <el-col :span="16">
                          {{ cur_po.ComContact }}
                        </el-col>
                      </el-row>
                      <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Tel") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        {{ cur_po.ComTEL }}
                        {{ cur_po.ComExt ? "- " + cur_po.ComExt : "" }}
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                        {{ $t("Fax") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        {{ cur_po.ComFAX }}
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Address") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <div>{{ cur_po.ComAddress1 }}</div>
                        <div>{{ cur_po.ComAddress2 }}</div>
                        <div>
                          {{ cur_po.ComCity }},
                          {{ province_list[cur_po.ComProvinceIDn].Province }}
                          {{ country_list[cur_po.ComCountryIDn].Country }}
                          {{ cur_po.ComZIP }}
                        </div>
                      </el-col>
                    </el-row>
                  </section>
                </div>
              </el-card>
            </el-col>
            <!-------------------------------------- 選擇Shipping Address-------------------------------------->
            <el-col :span="8">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row style="padding: 3px 0px">
                    <el-col :span="12">
                      <div class="so_card_title" style="padding-top: 5px">
                        {{ $t("Shipping Address") }}
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <!----------------------------------- Shipping address form @else ----------------------------------->
                <section>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Name") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTTitle }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Contact") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTContact }}
                    </el-col>
                  </el-row>
                  <el-row v-if="cur_po.ShipTLocationID">
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Tel") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTTEL }}
                      {{ cur_po.ShipTExt ? "- " + cur_po.ShipTExt : "" }}
                    </el-col>
                  </el-row>
                  <el-row v-if="cur_po.ShipTLocationID">
                    <el-col :span="8" class="text-right pr-3" style="margin-top: 2px">
                      {{ $t("Fax") + " : " }}
                    </el-col>
                    <el-col :span="16">
                      {{ cur_po.ShipTFAX }}
                    </el-col>
                  </el-row>

                  <el-row v-if="cur_po.ShipTLocationID">
                    <el-col :span="8" class="text-right pr-3">
                        {{ $t("Address") + " : " }}
                      </el-col>
                    <el-col :span="16">
                      <div>{{ cur_po.ShipTAddress1 }}</div>
                      <div>{{ cur_po.ShipTAddress2 }}</div>
                      <div>
                        {{ cur_po.ShipTCity }},
                        {{ province_list[cur_po.ShipTProvinceIDn].Province }}
                        {{ country_list[cur_po.ShipTCountryIDn].Country }}
                        {{ cur_po.ShipTZIP }}
                      </div>
                    </el-col>
                  </el-row>
                </section>
              </el-card>
            </el-col>
          </el-row>
        </el-collapse-item>
        <!-------------------------------------- 購買訂單詳細 -------------------------------------->
        <el-collapse-item
          class="collapseheader podetail"
          :title="$t('PO Detail')"
          name="PODetail"
          @click.native="calculate_el_select_width2()"
        >
          <el-row :gutter="10" style="padding: 0px 10px">
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('PO Number') + ':'">
                <b-input 
                  class="form-control fred2" 
                  style="height: 46px" 
                  v-model="cur_po.OrderID" 
                  disabled />
              </b-input-group>
            </el-col>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Term') + ':'">
                <el-select
                  class="el-dropdown3"
                  v-model="cur_po.PayPeriodIDn"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                  <el-option
                    v-for="(period, idx) in pay_period_list"
                    :key="idx"
                    :label="period.PayPeriod"
                    :value="parseInt(period.IDn)"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="padding: 0px 10px">
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Order Date') + ':'">
                <flat-pickr
                  v-model="cur_po.OrderDate"
                  :config="pickr_config"
                  class="form-control"
                  style="height: 46px"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Currency') + ':'">
                <el-select
                  class="el-dropdown3"
                  v-model="cur_po.CurrencyIDn"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                  <el-option
                    v-for="item in currency_List"
                    :key="item.IDn"
                    :label="item.Currency"
                    :value="item.IDn"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="padding: 0px 10px">
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Arrival Date') + ':'">
                <flat-pickr
                  v-model="cur_po.ArrivalDate"
                  :config="pickr_config"
                  class="form-control"
                  style="height: 46px"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
          </el-row>
          <hr class="solid mb-2 mt-0" />
          <el-row :gutter="10" style="padding: 10px">
            <el-col :span="8">
              <b-input-group class="mb-2" :prepend="$t('Shipping Method') + ':'">
                <el-select
                  class="el-dropdown4"
                  v-model="cur_po.ShipStatus"
                  :disabled="cur_po.Status == 'Draft'? false : true"
                >
                  <el-option
                    v-for="item in shipping_status_option"
                    :key="item.IDn"
                    :label="item.Status"
                    :value="item.Status"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="8">
              <el-row>
                <el-col class="mt-1" :span="8">
                  <span style="float: right; margin-top: 2px; margin-right: 10px;">{{$t('Carrier') + ':'}}</span>
                </el-col>
                <el-col :span="14">
                  <el-autocomplete
                    class="inline-input fred3"
                    id="carrier_name_textbox"
                    v-model="cur_po.CarrierName"
                    value-key="CarrierName"
                    :fetch-suggestions="querySearch2"
                    :placeholder="$t('Carrier')"
                    :disabled="cur_po.Status == 'Draft'? false : true"
                    @select="selectCarrier"
                    @input="resetCarrier"
                  ></el-autocomplete>
                </el-col>
              </el-row>
            </el-col>
            
            <!-- Container table  -->
            <el-col :span="7">
              <el-row v-for="(container, index) in containerList" :key="index">
                <el-col class="mt-1" :span="8">
                  <span style="float: right; margin-top: 2px; margin-right: 10px;">{{$t('Container No') + ':'}}</span>
                </el-col>
                <el-col :span="14">
                  <b-form-input
                    class="form-control fred3"
                    v-model="containerList[index].ContainerNumber"
                    size="nm"
                    :disabled="cur_po.Status == 'Draft'? false : true"
                  >
                  </b-form-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-collapse-item>
        <!-------------------------------------- 產品列表 -------------------------------------->
        <el-collapse-item
          class="collapseheader productlist"
          :title="$t('Product List')"
          name="ProductList"
        >
          <el-skeleton :loading="loadingProducts" animated>
            <template slot="template">
              <el-skeleton-item
                variant="rect"
                style="width: 100%; height: 300px;"
              />
            </template>
            <b-card>
              <el-table
                border
                v-if="show_listed_products"
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :cell-style="{ textAlign: 'center' }"
                :data="cur_po_product_old"
                :style="{ marginBottom: 1.5 + 'rem' }"
                :default-expand-all=true
              >
                <el-table-column type="expand" width="1px">
                  <template slot-scope="props" >
                    <el-row>
                      <el-col :span="2" class="text-right">
                        <label for="item_remark" style="margin-top: 5px; margin-right: 10px">{{ $t("Remarks") + " : " }}</label>
                      </el-col> 
                      <el-col :span="21">
                        <b-textarea
                          id="item_remark"
                          :placeholder="$t('Remarks')"
                          type="textarea"
                          rows="1"
                          max-rows="2"
                          v-model="props.row.Remarks"
                          no-resize
                          style="max-height: 30px; overflow-y: hidden; line-height: 0.6;"
                          :disabled="cur_po.Status == 'Complete'"
                        />
                      </el-col>
                    </el-row>
                  </template>
                </el-table-column>
                <el-table-column prop="PLU" class="p-0" min-width="80px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="Barcode" class="p-0" min-width="80px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
                  </template>
                </el-table-column>
                <el-table-column class="p-0" min-width="200px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Product Name") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <div v-if="row.OrderQty>0">
                    {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
                    </div>
                    <div v-else style="text-decoration: line-through">
                    {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="Unit" class="p-0" min-width="40px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="UnitCost" class="p-0" min-width="60px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Unit Cost") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.UnitCost }}
                  </template>
                </el-table-column>
                <el-table-column v-if="!cur_is_return && !cur_is_receive" prop="VendorPLU" class="p-0" min-width="80px">
                  <template slot="header">
                    <div v-if="!cur_is_return && !cur_is_receive" class="w-100 p-0 text-center">{{ $t("Vendor PLU") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    <el-input v-if="!cur_is_return && !cur_is_receive" size="mini" v-model="row.VendorPLU" :disabled="cur_po.Status == 'Complete'"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="OrderQty" class="p-0" min-width="60px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Qty") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="TotalCost" class="p-0" min-width="60px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Subtotal") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ "$ " + row.TotalCost }}
                  </template>
                </el-table-column>

              </el-table>
              <el-row :gutter="6">
                <el-col :span="12">
                  <label for="order_remark" >{{ $t("Order Remarks") + " : " }}</label>
                  <b-textarea
                    id="order_remark"
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="6"
                    max-rows="8"
                    v-model="cur_po.Remarks"
                    :disabled="cur_po.Status == 'Complete'"
                  >
                  </b-textarea>
                </el-col>
                <el-col :span="7" :offset="3">
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Subtotal') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.SubTotal).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="!cur_is_return && false">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Discount') + ' : ' }}
                    </el-col>
                    <el-col :span="8">
                      {{ parseFloat(cur_po.Discount).toFixed(2) }}
                    </el-col>
                    <el-col :span="8">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="input_discount_rate"
                      >
                        {{ $t("Change") }}
                      </el-button>
                    </el-col>
                  </el-row>
                  <el-row v-for="(tax, idx) in cur_po_tax" :key="idx">
                    <el-col :span="8" class="text-right pr-3">
                      {{ tax.TaxType + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(tax.TaxAmount).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Other Fee') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.OtherFees).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Shipping Fee') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.ShipFee).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Total') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.TotalAmount).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="!cur_is_return && !cur_is_receive">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Paid Amount') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.PaidAmount).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="!cur_is_return && !cur_is_receive">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Returned') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ isNaN(parseFloat(cur_po.ReturnedTotal)) ? '0.00' : parseFloat(cur_po.ReturnedTotal).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="!cur_is_return && !cur_is_receive">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Credited') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.CreditedTotal).toFixed(2) }}
                    </el-col>
                  </el-row>
                  <el-row v-if="!cur_is_return && !cur_is_receive">
                    <el-col :span="8" class="text-right pr-3">
                      {{ $t('Balance') + ' : ' }}
                    </el-col>
                    <el-col :span="16">
                      {{ parseFloat(cur_po.Balance).toFixed(2) }}
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </b-card>
          </el-skeleton>
        </el-collapse-item>
        <!-------------------------------------- 接收 -------------------------------------->
        <el-collapse-item
          class="collapseheader"
          :title="$t('Receive')"
          name="Receive"
        >
          <el-skeleton :loading="loadingReceive" animated>
            <template slot="template">
              <el-skeleton-item
                variant="rect"
                style="width: 100%; height: 300px;"
              />
            </template>
            <div style="margin: 16px">
              <el-table
                class="table-responsive table product-table mt-2"
                style="width: 100%"
                header-row-class-name="thead-light"
                :data="cur_po_receive"
                :cell-style="{ textAlign: 'center'}"
                :header-row-style="{ textAlign: 'center'}"
                :style="{ marginBottom: 1.5 + 'rem' }"
              >
              <!-- row-key="id" -->
                <el-table-column class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Create Date") }}</div>
                  </template>
                  <template v-slot="{ row }">
                    {{ row.ReceiveDate ? row.ReceiveDate.substr(0,10) : "" }}
                  </template>
                </el-table-column>
                <el-table-column prop="OrderID" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">OrderID</div>
                  </template>
                </el-table-column>
                <el-table-column prop="POOrderID" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">PO OrderID</div>
                  </template>
                </el-table-column>
                <el-table-column prop="Status" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="CreateByName" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Create By") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="EndByName" class="p-0" min-width="100px">
                  <template slot="header">
                    <div class="w-100 p-0 text-center">{{ $t("Complete By") }}</div>
                  </template>
                </el-table-column>
                <el-table-column class="p-0" min-width="80px">
                  <template slot="header">
                    <div style="text-align: center">
                      <el-button class="btn-inner--icon" 
                        v-if="cur_po.Status!='Complete'"
                        type="warning"
                        plain
                        @click="init_receive('new')">
                        <i class="el-icon-plus" style="font-size: 1.25rem"></i>{{ "&nbsp;New" }}
                      </el-button>
                    </div>
                  </template>
                  <template v-slot="{ row }">
                    <div v-if="row.Status==='Draft'">
                      <el-button class="btn-inner--icon"
                        type="primary"
                        plain
                        @click="init_receive('edit', row)">
                        <i class="el-icon-edit" style="font-size: 1.25rem"></i>{{ "&nbsp;&nbsp;Edit" }}
                      </el-button>
                    </div>
                    <div v-else>
                      <el-button class="btn-inner--icon"
                        type="info"
                        plain
                        @click="init_receive('complete', row)">
                        <i class="el-icon-s-fold" style="font-size: 1.25rem"></i>{{ "&nbsp;View" }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column class="p-0" min-width="100px">
                  <template slot="header">
                  </template>

                  <template v-slot="{ row }">
                    <base-button type="primary" size="sm" icon @click="pay_receive(row)">{{ $t("Pay") }}</base-button>
                  </template>
                </el-table-column> -->
              </el-table>
            </div>
          </el-skeleton>
        </el-collapse-item>
      </el-collapse>

      <!-------------------------------------- PO訂單詳情分頁 Footer -------------------------------------->
      <b-card v-if="currpage == 'edit'">

        <!-------------------------------------- PO訂單詳情分頁 Pending buttons-------------------------------------->
        <div v-if="cur_po.Status == 'Pending'" class="text-right">
          <span>
            <el-button type="primary" @click="submit_click('Pending', 'SaveChange')">
              {{ $t("Save Change") }}
            </el-button>
            <el-button type="primary" v-if="false" @click="submit_click('Complete')">
              {{ $t("Complete") }}
            </el-button>
            <el-button type="primary" @click="cancel_click">
              {{ $t("Back") }}
            </el-button>
          </span>
        </div>
        <!-------------------------------------- PO訂單詳情分頁 Complete buttons-------------------------------------->
        <div v-if="cur_po.Status == 'Complete'" class="text-right">
          <span>
            <el-button type="primary" @click="cancel_click">
              {{ $t("Back") }}
            </el-button>
          </span>
        </div>

      </b-card>
    </div>

    <!-------------------------------------- 添加收貨記錄 Modal -------------------------------------->
    <b-modal ref="add-receive-modal" :title="$t('Add Receive Order')" id="search_modal" size="xl" scrollable >
      <h2 v-if="receive_mode!='new'">
        {{ "Status: " +  cur_RE_status}}
      </h2>
      <h2 v-else>
        {{ "Status: " +  "New"}}
      </h2>
      <h1 v-if="receive_mode!='new'">
        {{ "Order #: " + cur_RE_orderNo }}
        <button v-if="!isUnlocked && receive_mode=='complete' && cur_po.Status!='Complete'"
          type="button"
          class="btn btn-primary"
          @click="unlockOrder()"
          style="float: right">
          {{ $t('Unlock') }}
        </button>
      </h1>
      <b-card v-if="receive_mode=='new'">
        <el-table
          border
          v-if="show_listed_products"
          class="table-responsive table product-table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :cell-style="{ textAlign: 'center' }"
          :data="cur_po_product"
          :style="{ marginBottom: 1.5 + 'rem' }"
          :default-expand-all=true
        >
          <el-table-column type="expand" width="1px">
            <template slot-scope="props" >
              <el-row>
                <el-col :span="2" class="text-right">
                  <label for="item_remark" style="margin-top: 5px; margin-right: 10px">{{ $t("Remarks") + " : " }}</label>
                </el-col> 
                <el-col :span="21">
                  <b-textarea
                    id="item_remark"
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="1"
                    max-rows="2"
                    v-model="props.row.Remarks"
                    no-resize
                    style="max-height: 30px; overflow-y: hidden; line-height: 0.6;"
                  />
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="PLU" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="Barcode" class="p-0" min-width="100px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="200px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Product Name") }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="row.OrderQty>0">
              {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
              </div>
              <div v-else style="text-decoration: line-through">
              {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Unit" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="75px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "S/N" }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="parseInt(row.HasSerialNumber) == 1" @click="selectSN(row, receive_mode)">
                <i class="el-icon-connection hover_hand red_color"></i>
              </div>
              <div v-else>
                {{ "-" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="OrderQty" class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Ordered Qty" }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="PendingQty" class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Pending Qty" }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Receive Qty" }}</div>
            </template>
            <template v-slot="{ row }">
              <b-form-input size="sm" v-model="row.ReceivedQty" @change="resetSN_row(row)"/>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
      <b-card v-else-if="receive_mode=='complete'">
        <el-table
          border
          v-if="show_listed_products"
          class="table-responsive table product-table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :cell-style="{ textAlign: 'center' }"
          :data="isUnlocked ? re_edit_complete_data_array : re_complete_data_array"
          :style="{ marginBottom: 1.5 + 'rem' }"
          :default-expand-all=true
        >
          <el-table-column type="expand" width="1px">
            <template slot-scope="props" >
              <el-row>
                <el-col :span="2" class="text-right">
                  <label for="item_remark" style="margin-top: 10px; margin-right: 10px">{{ $t("Remarks") + " : " }}</label>
                </el-col> 
                <el-col :span="21">
                  <b-textarea
                    id="item_remark"
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="1"
                    max-rows="2"
                    v-model="props.row.Remarks"
                    no-resize
                    style="max-height: 30px; overflow-y: hidden; line-height: 0.6;"
                    :disabled="!isUnlocked"
                  />
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="PLU" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="Barcode" class="p-0" min-width="100px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="200px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Product Name") }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="row.OrderQty>0">
              {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
              </div>
              <div v-else style="text-decoration: line-through">
              {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Unit" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="75px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "S/N" }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="row.SerialNumber || (parseInt(row.HasSerialNumber) == 1)" @click="selectSN(row, receive_mode)">
                <i class="el-icon-connection hover_hand red_color"></i>
              </div>
              <div v-else>
                {{ "-" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="OrderQty" class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Ordered Qty" }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="PendingQty" class="p-0" min-width="110px" v-if="isUnlocked">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Pending Qty" }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Receive Qty" }}</div>
            </template>
            <template v-slot="{ row }">
              <b-form-input size="sm" v-model="row.ReceivedQty" @blur="validateReceivedQty_row(row)" :disabled="!isUnlocked" />
            </template>
          </el-table-column>
        </el-table>
      </b-card>
      <b-card v-else-if="receive_mode=='edit'">
        <el-table
          border
          v-if="show_listed_products"
          class="table-responsive table product-table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :cell-style="{ textAlign: 'center' }"
          :data="re_draft_data_array"
          :style="{ marginBottom: 1.5 + 'rem' }"
          :default-expand-all=true
        >
          <el-table-column type="expand" width="1px">
            <template slot-scope="props" >
              <el-row>
                <el-col :span="2" class="text-right">
                  <label for="item_remark" style="margin-top: 10px; margin-right: 10px">{{ $t("Remarks") + " : " }}</label>
                </el-col> 
                <el-col :span="21">
                  <b-textarea
                    id="item_remark"
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="1"
                    max-rows="2"
                    v-model="props.row.Remarks"
                    no-resize
                    style="max-height: 30px; overflow-y: hidden; line-height: 0.6;"
                  />
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="PLU" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="Barcode" class="p-0" min-width="100px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="200px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Product Name") }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="row.OrderQty>0">
              {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
              </div>
              <div v-else style="text-decoration: line-through">
              {{ row.Name2 && po_show_name2 ? row.Name1 + "(" + row.Name2 + ")" : row.Name1}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Unit" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="75px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "S/N" }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="parseInt(row.HasSerialNumber) == 1" @click="selectSN(row, receive_mode)">
                <i class="el-icon-connection hover_hand red_color"></i>
              </div>
              <div v-else>
                {{ "-" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="OrderQty" class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Ordered Qty" }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="PendingQty" class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Pending Qty" }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="110px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ "Receive Qty" }}</div>
            </template>
            <template v-slot="{ row }">
              <b-form-input size="sm" v-model="row.ReceivedQty" @change="updateReceivedQty(row)"/>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
      <template #modal-footer>
        <!--
          Draft         -> Creating a new Draft
          DraftDraft    -> Updating an existing Draft
          Post          -> Posting a new Order
          PostDraft     -> Posting an existing Draft
          EditCompleted -> Editing a completed Order
        -->
        <button type="button" class="btn btn-primary" @click="submitRE('EditCompleted', RE_idn)" v-if="isUnlocked">
          {{ $t('Save') }}
        </button>
        <button type="button" class="btn btn-danger" @click="close_add_receive_model">
          {{ $t('Close') }}
        </button>
        <el-button type="primary"  class="btn btn-primary" @click="print_order('RE')" v-if="receive_mode=='complete' && !isUnlocked">
          {{ $t("Print RE") }}
        </el-button>
        <button type="button" class="btn btn-warning" @click="submitRE('Draft', '')" v-if="receive_mode=='new'">
          {{ $t('Save as Draft') }}
        </button>
        <button type="button" class="btn btn-warning" @click="submitRE('DraftDraft', RE_idn)" v-if="receive_mode=='edit'">
          {{ $t('Save as Draft') }}
        </button>
        <button type="button" class="btn btn-primary" @click="submitRE('PostDraft', RE_idn)" v-if="receive_mode=='edit'">
          {{ $t('Add') }}
        </button>
        <button type="button" class="btn btn-primary" @click="submitRE('Post', '')" v-if="receive_mode=='new'">
          {{ $t('Add') }}
        </button>
      </template>
    </b-modal>


    <!-- SN select dialogue -->
    <b-modal
      ref="select-sn-modal"
      :title="$t('Input S/N')"
      id="select-sn-modal"
      size="sm"
      scrollable 
    >
      <div v-if="sn_mode!='complete' || isUnlocked">
        <el-row>
          <el-col :span="18">
            <b-input autocomplete="off" class="form-control" v-model="input_SN" v-on:keydown.enter="addSN_enter_keydown_handler"/>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-plus" @click="addSN" style="margin-left: 20px"></el-button>
          </el-col>
        </el-row> 
        <el-row>
          <el-table
            id="inputSNTable"
            ref="inputSNTable"
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            height=300
            header-row-class-name="thead-light"
            :data="display_SN_array"
          >
            <el-table-column>
              <template slot="header">
                <div style="margin-left: 10px">{{ $t("Serial Number") }}</div>
              </template>
              <template slot-scope="scope" >
                <span style="margin-left: 15px" @click="removeSN(scope.row)"> <i class="el-icon-close" style="font-size: 1rem"></i> </span>
                <span style="margin-left: 20px"> {{ scope.row }} </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          {{display_SN_array.length}} / {{parseInt(cur_po_product_cur_row.ReceivedQty)}}
        </el-row>
      </div>
      <div v-else>
        <el-table
          id="inputSNTable"
          ref="inputSNTable"
          class="table-responsive table product-table mt-2"
          style="width: 100%"
          height=300
          header-row-class-name="thead-light"
          :data="display_SN_array"
        >
          <el-table-column>
            <template slot="header">
              <div style="margin-left: 10px">{{ $t("Serial Number") }}</div>
            </template>
            <template slot-scope="scope" >
              <span style="margin-left: 15px"> {{ scope.row }} </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="submitSN(sn_mode, isUnlocked)" v-if="sn_mode!='complete' || isUnlocked">
          {{ $t('Submit') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_select_sn_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import {
  Card,
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Radio,
  Select,
  Option,
  OptionGroup,
  Autocomplete,
  Icon,
  Loading,
  Cascader,
  CascaderPanel,
  Skeleton,
  SkeletonItem,
} from "element-ui";
import SearchAutocomplete from "../Components/SearchAutocomplete";
// import InputAutocomplete from "../InputAutocomplete";
// import InputAutocomplete2 from "../InputAutocomplete2";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
// import { XlsxRead, XlsxWorkbook, XlsxSheet, XlsxDownload} from "vue-xlsx";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Card.name]: Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Radio.name]: Radio,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Autocomplete.name]: Autocomplete,
    [flatPicker.name]: flatPicker,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [Cascader.name]: Cascader,
    [CascaderPanel.name]: CascaderPanel,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    SearchAutocomplete,
    // InputAutocomplete,
    // InputAutocomplete2,
    // XlsxRead,
    // XlsxWorkbook,
    // XlsxSheet, 
    // XlsxDownload,
    // XlsxJson,
    // AddressInput,
  },
  data() {
    return {
      cascader_options: [],
      cascader_selected: [],
      cascader_search_array: [],

      // PreOrder: null,
      token: "",
      user_id: "",
      current_page: 1,
      page_total: 0,
      per_page: 20,
      delay_timer: null,
      po_delay_timer: null,
      po_show_name2: false,

      currpage: "list",
      currreturnpage: "list",
      activeName: "Pending", // Draft Pending Complete Shipped Deleted Back PreOrder Contract Special

      // product_search: "",
      containerList:[{"ContainerNumber":''}],
      carrierList:[{"CarrierNumber":''}],
      vendor_list: [],
      vendor_cname_list: [],
      pay_period_list: [],
      carrier_list: [],
      carrier_names: [],

      country_list: [],
      province_list: [],
      city_list: [],
      data_type_list: [{
          value: "",
          label: ""
        },{
          value: "Order",
          label: "Order Date"
        }, {
          value: "Complete",
          label: "Complete Date"
      }],
      cur_address_list: [],

      show_listed_products: true,
      showReturn: false,
      showReceive: false,
      showOutbound: false,
      showInbound: false,
      showPayment: true,
      PO_status: "",
      activeCollapse1: ["Receive", "ProductList"],

      store_list: [],
      sales_list: [],

      isOpen: false,
      search: "",
      search_results: [],
      cur_address: {},
      cur_provincelist: [],
      cur_vendor_provincelist: [],
      cur_billing_provincelist: [],
      cur_shipping_provincelist: [],
      new_vendor_provincelist: [],
      new_address: {
        IDn: 0,
        Type: "vendor",
        ReferenceIDn: 0,
        BillingFlag: 0,
        ShippingFlag: 0,
        Status: 1,
        Name: "",
        Street1: "",
        Street2: "",
        AddressCity: "",
        ProvinceIDn: 2,
        AddressProvince: "British Columbia",
        CountryIDn: 1,
        AddressCountry: "Canada",
        ZIP: "",
        Remarks: "",
      },
      new_vendor: {
        Name: "",
        OtherName: "",
        Tel: "",
        CompanyType: "",
        Address1:"",
        Address2: "",
        ProvinceIDn: 2,
        CountryIDn: 1,
        ZIP: "",
        PaymentTerm: "",
        Contact: {
          FirstName: "",
          LastName: "",
          Tel: "",
          Email: "",
          Position: "",
        },
      },
      loading: false,
      vendor_address_edit: {
        Address1: "",
        Address2: "",
        AddressCity: "",
        ProvinceIDn: "",
        CountryIDn: "",
        ZIP: "",
      },
      vendor_credit: "",

      // Search condition
      cur_start_dt: "",
      cur_end_dt: "",
      cur_date_type: "", // Order, Complete
      cur_search_input: "",
      cur_vendor: "",
      cur_vendor_id: 0,
      cur_vendor_name: "",
      cur_order_no: "",
      cur_receive_no: "",
      cur_invoice_no: "",
      cur_remarks: "",
      cur_product: "",
      cur_product_idn: "",      
      cur_LocationID: "",
      cur_user_idn: 0, 
      componentKey_SearchAutocomplete: 0,
      selected_SearchAutocomplete: false,
      
      // product search conditions
      pro_search_plu: "",
      pro_search_name: "",
      pro_search_description: "",
      pro_search_unit: "",
      pro_search_qty: "",
      pro_search_onhold: "",
      pro_search_onorder: "",

      quick_add_input: "",
      isNoData: false,

      editPayment: false,

      po_qty_changed: false,

      cur_search_departmentIDn: 0,
      cur_search_categoryIDn: "",
      cur_searched_categorys: [],
      cur_searched_products: [],
      cur_listed_products: [],

      full_product_list: [],
      full_SN_products_list: [],
      buy_product_list: [],
      cur_product_list: [],
      selected_product_list: [],
      payment_method_list: [],
      payment_type_list: [],
      credit_type_list: [],
      company_typelist: [],
      pay_periodlist: [],
      positionlist: [],
      cur_shipping_list: [],

      cur_payment_edit: {},
      cur_credit_edit: {},
      new_payment: {
        IDn: 0,
        OrderType: "PO",
        ReferenceIDn: "",
        CustomerIDnType: "Vendor", 
        CustomerIDn: 0,
        InvoiceNo: "",
        InvoiceAmount: 0.00,
        PaymentMethodID: "Cash",
        PaymentAmount: 0.00,
        Remarks: "",
        CreateBy: "",
        PaymentDateTime: null,
        CreateDateTime: null,
        PayType: "",
        Balance: 0.00,
        Status: "",
      },
      new_credit: {
        IDn: 0,
        OrderType: "PO",
        ReferenceIDn: 0,
        CustomerIDnType: "Vendor",
        CustomerIDn: 0,
        InvoiceNo: "",
        InvoiceAmount: 0.0,
        PaymentMethodID: "Cash",
        PaymentAmount: 0.0,
        Remarks: "",
        CreateBy: "",
        PaymentDateTime: null,
        CreateDateTime: null,
        PayType: "Redeem",
        Balance: 0.0,
        Status: "",
      },
      cur_po_list: [],
      cur_po_meta: {},
      cur_po: {},
      cur_re: {},
      cur_po_tax: [],
      cur_re_tax: [],
      cur_po_product: [],
      cur_re_product: [],
      cur_po_product_old: [],
      cur_po_return: [],
      cur_po_receive: [],
      cur_po_payment: [],
      cur_po_credit: [],
      cur_inbound: [],
      cur_outbound: [],
      loaded_return_data: false,
      loaded_receive_data: false,

      cur_discountRate: 0,
      showDiscountRate: false,
      cur_is_receive: false,
      cur_is_return: false,

      collection: [],
      import_file: null,
      import_file_warning: "",
      downloadsheet: { name: "po import", data: [ ["Barcode", "Name1", "Name2", "Qty", "Cost"] ] },

      // cur_po_product_tax: [],
      new_po: {
        POIDn: 0,
        OrderID: "",
        VendorInvoiceNo: "",

        LocationID: "",
        ComTitle: "",
        ComContact: "",
        ComAddress1: "",
        ComAddress2: "",
        ComCity: "",
        ComProvince: "",
        ComCountry: "",
        ComProvinceIDn: 2,
        ComCountryIDn: 1,
        ComZIP: "",
        ComTEL: "",
        ComExt: "",
        ComFAX: "",

        ShipTLocationID: "",
        ShipTTitle: "",
        ShipTContact: "",
        ShipTAddress1: "",
        ShipTAddress2: "",
        ShipTCity: "",
        ShipTProvince: "",
        ShipTCountry: "",
        ShipTProvinceIDn: "",
        ShipTCountryIDn: 1,
        ShipTZIP: "",
        ShipTTEL: "",
        ShipTExt: "",
        ShipTFAX: "",

        VendorIDn: "",
        PurchaseFName: "",
        PurchaseFOtherName: "",
        PurchaseFAddressIDn: 0,
        PurchaseFContact: "",
        PurchaseFAddress1: "",
        PurchaseFAddress2: "",
        PurchaseFCity: "",
        PurchaseFProvince: "",
        PurchaseFCountry: "",
        PurchaseFProvinceIDn: 2,
        PurchaseFCountryIDn: 1,
        PurchaseFZIP: "",
        PurchaseFTEL: "",
        PurchaseFExt: "",
        PurchaseFFAX: "",

        PayPeriodIDn: 1,
        
        CreateDate: null,
        EndDate: null,
        OrderDate: null,
        ArrivalDate: null,

        CreateByIDn: 0,
        CreateByName: "",
        EndByIDn: 0,
        EndByName: "",

        CarrierIDn: 0,
        CarrierName: "",
        ContainerNo: "",
        ShipStatus: "",

        Remarks: "",
        Status: "Draft",

        ShipFee: 0.0,
        TAXFlag: "",
        SubTotal: 0.0,
        TotalTax: 0.0,
        OtherFees: 0.0,
        TotalAmount: 0.0,
        Paid: 0,
        PaidAmount: 0.0,
        Discount: 0.0,

        Currency: "CAD",
        CurrencyIDn: 1,
        CurrencyRate: 1,
      },
      picker_config2: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: "today", // New Date();  YYYY-MM-DD HH:MM
      },
      pickr_config: {
        allowInput: true,
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        // time_24hr: true
      },
      currency_List: [{
          IDn: 1,
          Currency: "CAD",
          Rate: 1
        }, {
          IDn: 2,
          Currency: "USD",
          Rate: 1.34
      }],
      shipping_status_option: [{
          IDn: 1,
          Status: "Delivery",
        }, {
          IDn: 2,
          Status: "Pick Up",
        },{
          IDn: 3,
          Status: "Price Check",
        },{
          IDn: 4,
          Status: "Ship Collect",
        },{
          IDn: 5,
          Status: "Ship Prepaid",
      }],
      disable_vendor_input: "",
      disable_billing_input: "",
      disable_shipping_input: "",
      receive_mode: "",
      sn_mode: "",
      re_complete_data_array: [],
      re_draft_data_array: [],
      re_edit_complete_data_array: [],
      cur_RE_orderNo: "",
      cur_RE_status: "",
      RE_idn: "",
      isUnlocked: false,
      po_product_old: "",
      cur_po_receive_cur_row: "",
      total_received_product_array: [],
      cur_po_receive_completedOnly: [],
      loadingTabContent: true,
      loadingReceive: true,
      loadingProducts: true,

      // for S/N
      cur_po_product_cur_row: "",
      input_SN: "",
      inputted_SN_array: [],
      saved_SN_array: [],
      display_SN_array: [],
      all_SN_product_array: [],
    };
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    }
  },
  methods: {
    hasUtilityAuth,
    querySearch2(queryString, cb) {
      var links = Object.values(this.carrier_list); 
      var results = queryString ? links.filter(this.createFilter2(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter2(queryString) {
      return (link) => {
        return (link.CarrierName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    selectCarrier(item){
      this.cur_po.CarrierIDn = item.IDn;
      this.cur_po.CarrierName = item.CarrierName;
    },
    resetCarrier(){
      this.cur_po.CarrierIDn = 0;
    },
    checkLoadingState(obj){
      const interval = setInterval(()=> {
        let loading = this.waitingForResponse(obj);
        if(!loading){
          this.loadingProducts = this.loadingReceive;
          clearInterval(interval);
        }
      }, 10);
    },
    waitingForResponse(obj){
      if(obj == "loadingTabContent"){
        return this.loadingTabContent;  // true or false
      } else if (obj == "loadingReceive"){
        return this.loadingReceive;  // true or false
      }
    },
    add_cur_product_qty() {
      var dpt, cat, prd;
      for (var i = 0; i < this.full_product_list.length; i++) {
        dpt = this.full_product_list[i]['IDn'];
        for (var j = 0; j < this.full_product_list[i]["Categorys"].length; j++) {
          cat = this.full_product_list[i]["Categorys"][j]['IDn'];
          for (var k = 0; k < this.full_product_list[i]["Categorys"][j]["Products"].length; k++) {
            prd = this.full_product_list[i]["Categorys"][j]["Products"][k]['IDn'];
            if (this.inventory_products.hasOwnProperty(dpt) &&
                this.inventory_products[dpt].hasOwnProperty(cat) &&
                this.inventory_products[dpt][cat].hasOwnProperty(prd)) {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = this.inventory_products[dpt][cat][prd].StoreQty;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreCost'] = this.inventory_products[dpt][cat][prd].StoreCost;
            } else {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = 0;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreCost'] = 0;
            }
          }
        }
      }
    },

    input_discount_rate() {
      this.cur_discountRate = "";
      this.showDiscountRate = true;
      var that = this;
      this.$nextTick(() => {
        that.$refs["discount_rate"].focus();
      });
    },
    set_cur_product_name(val) {
      this.cur_product = val.value;
      this.cur_product_idn = val.key;
      this.selected_SearchAutocomplete = true;
    },
    reset_cur_product_idn(val){
      this.cur_product_idn = "";
      this.selected_SearchAutocomplete = false;
    },
    handleClick(tab, event) {
      this.loadingTabContent = true;
      this.current_page = 1;
      this.page_total = 0;
      this.per_page = 20;
      this.cur_user_idn = 0;
      this.cur_LocationID = "";
      this.cur_product = "";
      this.cur_product_idn = "";
      this.cur_remarks = "";
      this.cur_invoice_no = "";
      this.cur_order_no = "";
      this.cur_receive_no = "";
      this.cur_vendor = "";
      this.cur_vendor_id = 0;
      this.cur_date_type = "";
      this.get_lists();
      this.checkLoadingState("loadingTabContent");
      this.forceRerender_SearchAutocomplete();
      // this.$forceUpdate();
    },
    forceRerender_SearchAutocomplete(){
      this.componentKey_SearchAutocomplete++;
    },
    encode_container_string(){
      this.cur_po.ContainerNo = "";
      for(let i=0; i<this.containerList.length; i++){
        let temp = Object.values(this.containerList[i]);
        this.cur_po.ContainerNo += temp[0];
        if(i != this.containerList.length - 1){
          this.cur_po.ContainerNo += ","
        }
      }
    },
    decode_container_string(){
      const tempArray = this.cur_po.ContainerNo.split(",");  // We use "," as the delimiter during encoding
      if(tempArray.length === 0){
        this.containerList = [{"ContainerNumber":''}];
        return;
      } else {
        this.containerList = [];
        for(let i=0; i<tempArray.length; i++){
          this.containerList.push({"ContainerNumber":tempArray[i]});
        }
      }
    },
    get_CarrierName(){
      if(this.cur_po.CarrierIDn && 
         this.cur_po.CarrierIDn>0 && 
         this.carrier_list[this.cur_po.CarrierIDn] )
      {
        this.cur_po.CarrierName = this.carrier_list[this.cur_po.CarrierIDn].CarrierName;
      }
    },
    matching_product(prod) {
      if ((!prod.PLU
          || this.pro_search_plu.length < 2
          || prod.PLU.search(this.pro_search_plu) >= 0) 
          &&
          (!prod.Name1
          || !prod.Name2
          || this.pro_search_name.length < 2
          || prod.Name1.search(this.pro_search_name) >= 0
          || prod.Name2.search(this.pro_search_name) >= 0)
          &&
          (!prod.Description
          || this.pro_search_description.length < 2
          || prod.Description.search(this.pro_search_description) >= 0)
          &&
          (!prod.Qty
          || this.pro_search_qty.length < 1
          || prod.Qty.search(this.pro_search_qty) >= 0)
          &&
          (!prod.OnHold
          || this.pro_search_onhold.length < 2
          || prod.OnHold.search(this.pro_search_onhold) >= 0)
          &&
          (!prod.OnOrder
          || this.pro_search_onorder.length < 2
          || prod.OnOrder.search(this.pro_search_onorder) >= 0)) {
        return true;
      }
      return false;
    },
    edit_po(row, POStatus) {
      if (!hasUtilityAuth('ReceivingEdit')) return;
      this.cur_po = JSON.parse(JSON.stringify(row));
      this.showPayment = false;
      this.loadingReceive = true;
      this.loadingProducts = true;
      this.PO_status = POStatus;
      // console.log("@edit_po() row:", row);
      this.set_edit_po();
    },
    set_edit_po() {
      this.po_qty_changed = false;
      this.showReturn = false;
      this.showReceive = false;
      this.showOutbound = false;
      this.showInbound = false;
      this.$refs["add-receive-modal"].hide();
      switch (this.cur_po.Status) {
        case "Draft":
          this.changed_country(this.cur_po.PurchaseFCountryIDn,1);
          this.changed_country(this.cur_po.ComCountryIDn,2);
          this.changed_country(this.cur_po.ShipTCountryIDn,3);
          break;
        case "Pending":
          this.showReturn = true;
          this.showReceive = true;
          this.showOutbound = true;
          this.showInbound = true;
          break;
        case "Complete":
          this.showReturn = true;
          this.showReceive = true;
          this.showOutbound = true;
          this.showInbound = true;
          break;
        case "Shipped":
          break;
        case "Deleted":
        case "Back":
        // case "PreOrder":
        case "Contract":
        case "Special":
          break;
      }

      this.currpage = "edit";
      this.find_vendor_name();
      this.get_CarrierName();
      this.decode_container_string();
      this.get_extra();
      this.reset_search_parameters();
      this.checkLoadingState("loadingReceive");
    },
    async build_product(data) {
      this.cur_listed_products = [];
      this.cascader_search_array = [];
      if ((this.cur_po.LocationID == undefined) || (this.cur_po.LocationID == '')) {
        alert("Please select store first");
        return;
      }
      if ((this.cur_po.VendorIDn == undefined) || (this.cur_po.VendorIDn <= 0)) {
        alert("Please select vendor first");
        return;
      }
      if(data && data!=undefined){
        this.cur_listed_products = data;
        this.cascader_search_array = data;
      } else {
        for (var i = 0; i < this.cur_product_list.length; i++) {
          for (var j = 0; j < this.cur_product_list[i].Categorys.length; j++) {
            for (var k = 0; k < this.cur_product_list[i].Categorys[j].Products.length; k++) {
              this.cur_listed_products.push(
                this.cur_product_list[i].Categorys[j].Products[k]
              );
              this.cascader_search_array.push(
                this.cur_product_list[i].Categorys[j].Products[k]
              );
            }
          }
        }
      }
      this.reset_search_parameters();
      this.show_listed_products = true;
      this.$refs["add-receive-modal"].show();
    },
    close_add_receive_model(){
      this.$refs["add-receive-modal"].hide();
    },
    checkDuplicates(arr,justCheck){
      var len = arr.length, tmp = {}, arrtmp = arr.slice(), dupes = [];
      arrtmp.sort();
      while(len--){
      var val = arrtmp[len];
      if (/nul|nan|infini/i.test(String(val))){
        val = String(val);
        }
        if (tmp[JSON.stringify(val)]){
          if (justCheck) {return true;}
          dupes.push(val);
        }
        tmp[JSON.stringify(val)] = true;
      }
      return justCheck ? false : dupes.length ? dupes : null;
    },
    validateReceivedQty_row(row){
      // console.log(row);
      
      let min_threshold = 0;
      let returned_product_array = [];
      let msg = "";
      if(row.SerialNumber != ""){
        let product_SN_Array = JSON.parse(row.SerialNumber);
        for(let i=0; i<this.all_SN_product_array.length; i++){
          if(this.all_SN_product_array[i].ProductIDn == row.ProductIDn){
            for(let j=0; j<product_SN_Array.length; j++){
              if(product_SN_Array[j]==this.all_SN_product_array[i].SN && this.all_SN_product_array[i].Status == 'Delete'){
                min_threshold++;
                returned_product_array.push(this.all_SN_product_array[i]);
              }
            }
          }
        }
      }
      for(let i=0; i<returned_product_array.length; i++) {
        msg += (returned_product_array[i].SN + " returned on " + (returned_product_array[i].UpdateDateTime).substring(0,19) + "\n");
      }
      if(row.ReceivedQty < min_threshold){
        alert("You have returned " + min_threshold + " item(s) from this order.\n\n" + 
              msg + 
              "\n" + 
              "You cannot receive less than " + min_threshold);
        row.ReceivedQty = min_threshold;
        return;
      }
    },
    validateReceivedQty(){
      for(var prod=0; prod < this.cur_po_product.length; prod++){
        if(isNaN(this.cur_po_product[prod].ReceivedQty)){
          alert("Please input numbers only. Error Code: AU78DS9000");
          return false;
        }
        if(Number(this.cur_po_product[prod].ReceivedQty) > Number(this.cur_po_product[prod].PendingQty)){
          alert("Receive Quantity cannot be greater than Pending Quantity.\nYou cannot receive more than what you have ordered. Error Code: AU78DS9001");
          return false;
        }
        if(Number(this.cur_po_product[prod].ReceivedQty) < 0){
          alert("Receive Quantity cannot be a negative number.\nPlease input positive numbers only. Error Code: AU78DS9002");
          return false;
        }
      }
      return true;
    },
    validateSerialNumber(){
      let allSNToBePushed = [];
      for(var prod=0; prod < this.cur_po_product.length; prod++){
        if ((parseInt(this.cur_po_product[prod].HasSerialNumber) == 1) && parseInt(this.cur_po_product[prod].ReceivedQty) > 0 ){

          if(!this.cur_po_product[prod].SerialNumber ||
             JSON.parse(this.cur_po_product[prod].SerialNumber).length !== parseInt(this.cur_po_product[prod].ReceivedQty)){
              alert("Please provide exactly " + parseInt(this.cur_po_product[prod].ReceivedQty) + " S/N . Error Code: AU78DS9003");
              return false;
          } else {
            allSNToBePushed = allSNToBePushed.concat(JSON.parse(this.cur_po_product[prod].SerialNumber));
            for(let i=0; i<this.all_SN_product_array.length; i++){
              for(let j=0; j<JSON.parse(this.cur_po_product[prod].SerialNumber).length; j++){
                if(this.all_SN_product_array[i].SN == JSON.parse(this.cur_po_product[prod].SerialNumber)[j]){
                  if(this.all_SN_product_array[i].Status == 'Active' || this.all_SN_product_array[i].Status == 'Delete'){
                    alert("This S/N exists in database.\n\nReference:\nSN: " + this.all_SN_product_array[i].SN
                        + "\nIDn: " + this.all_SN_product_array[i].IDn
                        + "\nProductIDn: " + this.all_SN_product_array[i].ProductIDn
                        + "\nStatus: " + this.all_SN_product_array[i].Status);
                    return;
                  } 
                }
              }
            }
          }
        }
      }
      // check if allSNToBePushed has duplicate element
      let result = this.checkDuplicates(allSNToBePushed);
      if(result){
        alert("Duplicated S/N entered\n\nDuplicated S/N: " + result);
        return false;
      }
      return true;
    },
    validateReceivedQty_forDraft(){
      
      // Create new RE and save as draft, no post
      if(this.receive_mode == 'new'){
        for(var prod=0; prod < this.cur_po_product.length; prod++){
          if(isNaN(this.cur_po_product[prod].ReceivedQty)){
            alert("Please input numbers only. Error Code: AU78DS9004");
            return false;
          }
          if(Number(this.cur_po_product[prod].ReceivedQty) < 0){
            alert("Receive Quantity cannot be a negative number.\nPlease input positive numbers only. Error Code: AU78DS9005");
            return false;
          }
        }
      }

      // Edit existing draft and save, no post
      else if(this.receive_mode == 'edit'){
        for(var prod=0; prod < this.re_draft_data_array.length; prod++){
          if(isNaN(this.re_draft_data_array[prod].ReceivedQty)){
            alert("Please input numbers only. Error Code: AU78DS9006");
            return false;
          }
          if(Number(this.re_draft_data_array[prod].ReceivedQty) < 0){
            alert("Receive Quantity cannot be a negative number.\nPlease input positive numbers only. Error Code: AU78DS9007");
            return false;
          }
        }
      }
      
      return true;
    },
    validateReceivedQty_forPostDraft(){

      // Post existing draft
      for(var prod=0; prod < this.re_draft_data_array.length; prod++){
        if(isNaN(this.re_draft_data_array[prod].ReceivedQty)){
          alert("Please input numbers only. Error Code: AU78DS9008");
          return false;
        }
        if(Number(this.re_draft_data_array[prod].ReceivedQty) > Number(this.re_draft_data_array[prod].PendingQty)){
          alert("Receive Quantity cannot be greater than Pending Quantity.\nYou cannot receive more than what you have ordered. Error Code: AU78DS9009");
          return false;
        }
        if(Number(this.re_draft_data_array[prod].ReceivedQty) < 0){
          alert("Receive Quantity cannot be a negative number.\nPlease input positive numbers only. Error Code: AU78DS9010");
          return false;
        }
      }
      return true;
    },
    validateSerialNumber_forPostDraft(){
      let allSNToBePushed = [];
      // Post existing draft
      for(var prod=0; prod < this.re_draft_data_array.length; prod++){
        if ((parseInt(this.re_draft_data_array[prod].HasSerialNumber) == 1) && parseInt(this.re_draft_data_array[prod].ReceivedQty) >= 0 ){
          if(!this.re_draft_data_array[prod].SerialNumber ||
             JSON.parse(this.re_draft_data_array[prod].SerialNumber).length !== parseInt(this.re_draft_data_array[prod].ReceivedQty)){
              alert("Please provide exactly " + parseInt(this.re_draft_data_array[prod].ReceivedQty) + " S/N . Error Code: AU78DS9011");
              return false;
          } else {
            allSNToBePushed = allSNToBePushed.concat(JSON.parse(this.re_draft_data_array[prod].SerialNumber));
            for(let i=0; i<this.all_SN_product_array.length; i++){
              for(let j=0; j<JSON.parse(this.re_draft_data_array[prod].SerialNumber).length; j++){
                if(this.all_SN_product_array[i].SN == JSON.parse(this.re_draft_data_array[prod].SerialNumber)[j]){
                  if(this.all_SN_product_array[i].Status == 'Active' || this.all_SN_product_array[i].Status == 'Delete'){
                    alert("This S/N exists in database.\n\nReference:\nSN: " + this.all_SN_product_array[i].SN
                        + "\nIDn: " + this.all_SN_product_array[i].IDn
                        + "\nProductIDn: " + this.all_SN_product_array[i].ProductIDn
                        + "\nStatus: " + this.all_SN_product_array[i].Status);
                    return;
                  } 
                }
              }
            }
          }
        }
      }
      // check if allSNToBePushed has duplicate element
      let result = this.checkDuplicates(allSNToBePushed);
      if(result){
        alert("Duplicated S/N entered\n\nDuplicated S/N: " + result);
        return false;
      }
      return true;
    },
    validateReceivedQty_forEditCompleted(){

      // Post completed order
      for(var prod=0; prod < this.re_edit_complete_data_array.length; prod++){
        if(isNaN(this.re_edit_complete_data_array[prod].ReceivedQty)){
          alert("Please input numbers only. Error Code: AU78DS9012");
          return false;
        }
        if(Number(this.re_edit_complete_data_array[prod].ReceivedQty) > Number(this.re_edit_complete_data_array[prod].PendingQty)){
          alert("Receive Quantity cannot be greater than Pending Quantity.\nYou cannot receive more than what you have ordered. Error Code: AU78DS9013");
          return false;
        }
        if(Number(this.re_edit_complete_data_array[prod].ReceivedQty) < 0){
          alert("Receive Quantity cannot be a negative number.\nPlease input positive numbers only. Error Code: AU78DS90014");
          return false;
        }
      }
      return true;
    },
    validateSerialNumber_forEditCompleted(){

      // Post completed order
      for(var prod=0; prod < this.re_edit_complete_data_array.length; prod++){
        if ((parseInt(this.re_edit_complete_data_array[prod].HasSerialNumber) == 1) && parseInt(this.re_edit_complete_data_array[prod].ReceivedQty) >= 0 ){
          if(!this.re_edit_complete_data_array[prod].SerialNumber ||
             JSON.parse(this.re_edit_complete_data_array[prod].SerialNumber).length !== parseInt(this.re_edit_complete_data_array[prod].ReceivedQty)){
              alert("Please provide exactly " + parseInt(this.re_edit_complete_data_array[prod].ReceivedQty) + " S/N . Error Code: AU78DS90015");
              return false;
          }
        }
      }
      return true;
    },
    isReceivingAnything(target){
      for(let i=0; i<target.length; i++){
        if(Number(target[i].ReceivedQty) > 0){
          return true;
        }
      }
      alert("Not receiving any items.");
      return false;
    },
    // test(val){        // @click.native="test"
    //   console.log(val);
    //   if(val == 'ProductList'){
    //     this.fakeLoading("loadingProducts", 150);
    //   }
    //   else if(val == 'Receive'){
    //     this.fakeLoading("loadingReceive", 150);
    //   }
    // },
    // fakeLoading(target, duration){
    //   if(target == 'loadingProducts'){
    //     this.loadingProducts = true;
    //     setTimeout(() => {
    //       this.loadingProducts = false;
    //     }, duration);
    //   }
    //   else if(target == 'loadingReceive'){
    //     this.loadingReceive = true;
    //     setTimeout(() => {
    //       this.loadingReceive = false;
    //     }, duration);
    //   }
    // },
    submitRE(RE_Status, re_IDn){

      if(RE_Status === "Post"){

        // Pre-check
        if(!this.isReceivingAnything(this.cur_po_product)) return;
        if(!this.validateReceivedQty()) return;
        if(!this.validateSerialNumber()) return;
        
        // In case of receiving 0 S/N products, fake a submitSN() to initialize Serial Number
        for(var prod=0; prod < this.cur_po_product.length; prod++){
          if(Number(this.cur_po_product[prod].ReceivedQty) == 0 && (parseInt(this.cur_po_product[prod].HasSerialNumber) == 1)) {
            this.cur_po_product_cur_row = this.cur_po_product[prod];
            this.submitSN(this.receive_mode, this.isUnlocked);
          }
        }
        
        // Update PendingQty 
        for(var prod=0; prod < this.cur_po_product.length; prod++){
          this.cur_po_product[prod].PendingQty -= this.cur_po_product[prod].ReceivedQty;
          this.cur_po_product[prod].PendingQty = parseFloat(this.cur_po_product[prod].PendingQty).toFixed(2);
        }

        // Update PO (Purchase Order) and create RE (Receive Order) and create IB (Inbound Order)
        this.post_po(this.cur_po.Status, RE_Status, re_IDn);
      }
      else if(RE_Status === "PostDraft"){

        // In case of receiving 0 S/N products, fake a submitSN() to initialize Serial Number
        for(var prod=0; prod < this.re_draft_data_array.length; prod++){
          if(Number(this.re_draft_data_array[prod].ReceivedQty) == 0 && 
              (parseInt(this.re_draft_data_array[prod].HasSerialNumber) == 1) &&
              this.re_draft_data_array[prod].SerialNumber === ""){
            this.cur_po_product_cur_row = this.re_draft_data_array[prod];
            this.submitSN(this.receive_mode, this.isUnlocked);
          }
        }

        // Pre-check
        if(!this.isReceivingAnything(this.re_draft_data_array)) return;
        if(!this.validateReceivedQty_forPostDraft()) return;
        if(!this.validateSerialNumber_forPostDraft()) return;

        this.cur_po_product = JSON.parse(JSON.stringify(this.re_draft_data_array));

        // Update PendingQty 
        for(var prod=0; prod < this.cur_po_product.length; prod++){
          this.cur_po_product[prod].PendingQty -= this.cur_po_product[prod].ReceivedQty;
          this.cur_po_product[prod].PendingQty = parseFloat(this.cur_po_product[prod].PendingQty).toFixed(2);
        }

        // Update PO (Purchase Order) and create RE (Receive Order) and create IB (Inbound Order)
        this.post_po(this.cur_po.Status, RE_Status, re_IDn);
      }
      else if(RE_Status === "Draft" || RE_Status === "DraftDraft"){

        // Pre-check
        if(!this.validateReceivedQty_forDraft()) return;

        // Update PO (Purchase Order) and create RE (Receive Order)
        this.post_po(this.cur_po.Status, RE_Status, re_IDn);
      }
      else if(RE_Status === "EditCompleted"){

        // Pre-check
        if(!this.isReceivingAnything(this.re_edit_complete_data_array)) return;
        if(!this.validateReceivedQty_forEditCompleted()) return;
        if(!this.validateSerialNumber_forEditCompleted()) return;
        
        this.cur_po_product = JSON.parse(JSON.stringify(this.re_edit_complete_data_array));

        // Update PendingQty 
        for(var prod=0; prod < this.cur_po_product.length; prod++){
          this.cur_po_product[prod].PendingQty -= this.cur_po_product[prod].ReceivedQty;
          this.cur_po_product[prod].PendingQty = parseFloat(this.cur_po_product[prod].PendingQty).toFixed(2);
        }
        
        // TODO: cur_po.Status may change when unlocking

        // Update PO (Purchase Order) > update RE (Receive Order) > create OB (Outbound Order) > create IB(Inbound Order)
        this.post_po(this.cur_po.Status, RE_Status, re_IDn);
      }

      // Close modal
      this.$refs["add-receive-modal"].hide();
    
    },
    async submit_click(status, button=null) {
      // this.cur_is_return = false;
      // this.cur_is_receive = false;
      this.post_po(status, button);
      this.showPayment = true;
      
      // UI control, depends on how fast post_po() finish
      let timesBeforeTimeout = 40;
      const interval = setInterval(()=> {
        this.calculate_el_select_width();
        if(timesBeforeTimeout == 0 || document.getElementsByClassName("el-dropdown").length > 0){
          clearInterval(interval);
        }
        timesBeforeTimeout--;
      }, 50)
    },
    cancel_click() {
      if ( this.cur_is_return || this.cur_is_receive) {
        this.show_listed_products = false;
        this.cur_is_return = false;
        this.cur_is_receive = false;
        this.set_edit_po();
        var that = this;
        this.$nextTick(() => {
          that.show_listed_products = true;
        });
      }
      else {
        this.currpage = "list";
      }
      this.showPayment = true;
      setTimeout(()=>{
        this.calculate_el_select_width()
      },50);
    },
    create_vendor_datalist() {
      this.vendor_cname_list = [];
      this.vendor_cname_list.push({IDn:"", CompanyName:""});
      for (var key in this.vendor_list) {
        // this.vendor_cname_list.push(this.vendor_list[key].CompanyName + "-" + this.vendor_list[key].IDn);
        let vendor = {IDn:this.vendor_list[key].IDn, CompanyName:this.vendor_list[key].CompanyName};
        this.vendor_cname_list.push(vendor);
      }
    },
    find_vendor_id() {
      this.cur_vendor_id = 0;
      for (var key in this.vendor_list) {
        if (this.cur_vendor == (this.vendor_list[key].CompanyName + "-" + this.vendor_list[key].IDn)) {
          // found it
          this.cur_vendor_id = this.vendor_list[key].IDn;
          break;
        }
      }
    },
    find_vendor_name() {
      this.cur_vendor_name = '';
      for (var key in this.vendor_list) {
        if (this.cur_po.VendorIDn == this.vendor_list[key].IDn) {
          // found it
          this.cur_vendor_name = this.vendor_list[key].CompanyName;
          break;
        }
      }
    },
    get_hasSerialNumber(){
      for(var dept=0; dept < this.full_product_list.length; dept++){
        for(var cat=0; cat < this.full_product_list[dept].Categorys.length; cat++){
          for(var prod=0; prod < this.full_product_list[dept].Categorys[cat].Products.length; prod++){
            if (parseInt(this.full_product_list[dept].Categorys[cat].Products[prod].HasSerialNumber) == 1) {
              if (!this.full_SN_products_list.includes(this.full_product_list[dept].Categorys[cat].Products[prod])) {
                this.full_SN_products_list.push(this.full_product_list[dept].Categorys[cat].Products[prod]);
              }
            }
          }
        }
      }
    },
    set_hasSerialNumber(){
      for(var po_prod=0; po_prod < this.cur_po_product.length; po_prod++){
        for(var sn_prod=0; sn_prod < this.full_SN_products_list.length; sn_prod++){
          if(this.cur_po_product[po_prod].ProductIDn === this.full_SN_products_list[sn_prod].IDn){
            this.cur_po_product[po_prod].HasSerialNumber = 1;
          } else {
            if (parseInt(this.cur_po_product[po_prod].HasSerialNumber) != 1) {
              this.cur_po_product[po_prod].HasSerialNumber = 0;
            }
          }
        }
      }
    },
    calculatePendingQty(){
      // Define and initiate "PendingQty" for each product in cur_po_product
      // TODO: recalculate each time OrderQty has changed
      this.cur_po_product.forEach(product => 
        (!product.PendingQty) ? product.PendingQty = product.OrderQty : "");
      for(var i=0; i<this.cur_po_product.length; i++){
        if(!this.cur_po_product[i].PendingQty){
          this.cur_po_product.PendingQty = this.cur_po_product.OrderQty;
        }
        else {
          for(var j=0; j<this.total_received_product_array.length; j++){
            if(this.cur_po_product[i].IDn == this.total_received_product_array[j].POProductIDn){
              this.cur_po_product[i].PendingQty = Number(this.cur_po_product[i].OrderQty) - Number(this.total_received_product_array[j].ReceivedQty);
            }
          }
        }
      }
      // console.log("@calculatePendingQty() cur_po_product:", this.cur_po_product);
    },
    set_ReceivedQty(){
      this.cur_po_product.forEach(product => 
        product.ReceivedQty = product.PendingQty);
    },
    async get_lists() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      var research = 0;
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }
      if (this.cur_date_type) {
        bodyFormData.append("cur_date_type", this.cur_date_type);
      }
      if (this.cur_vendor) {
        bodyFormData.append("VendorIDn", this.cur_vendor);
      }
      if (this.cur_order_no) {
        research = 1;
        bodyFormData.append("OrderID", this.cur_order_no);
      }
      if (this.cur_receive_no) {
        research = 1;
        bodyFormData.append("ReceiveOrderNo", this.cur_receive_no);
      }
      if (this.cur_invoice_no) {
        research = 1;
        bodyFormData.append("InvoiceNo", this.cur_invoice_no);
      }
      if (this.cur_remarks) {
        research = 1;
        bodyFormData.append("Remarks", this.cur_remarks);
      }
      if (this.cur_product_idn) {
        research = 1;
        bodyFormData.append("ProductIDn", this.cur_product_idn);
      }
      if (this.cur_LocationID) {
        research = 1;
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_user_idn) {
        research = 1;
        bodyFormData.append("CreateByIDn", this.cur_user_idn);
      }
      bodyFormData.append("Status", this.activeName);
      if (research) {
        bodyFormData.append("research", 1);
      }
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/list",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.Status != undefined) {
                that.activeName = rt.data.Status;
              }
              that.cur_po_list = rt.data.list_list;
              that.cur_po_meta = rt.data.list_meta;
              if (that.cur_po_meta.cnt != undefined) {
                that.page_total = that.cur_po_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              that.loadingTabContent = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    getCompletedRE(target){
      this.cur_po_receive_completedOnly = [];
      for(var i=0; i<target.length; i++){
        if(target[i].Status === "Complete"){
          this.cur_po_receive_completedOnly.push(target[i]);
        }
      }
    },
    set_total_received_product_array(){
      this.total_received_product_array = [];
      // GET total_received_product_array
      for(let i=0; i<this.cur_po_receive_completedOnly.length; i++){
        if(i == 0){
          // Set total_received_product_array to be the products[] of the first RE in this PO
          this.total_received_product_array = JSON.parse(JSON.stringify(this.cur_po_receive_completedOnly[i].Products));
          for(let j=0; j<this.total_received_product_array.length; j++){
            delete this.total_received_product_array[j].IDn;
            delete this.total_received_product_array[j].ReceiveIDn;
            delete this.total_received_product_array[j].OrderQty;

            if(this.total_received_product_array[j].SerialNumber){
              this.total_received_product_array[j].SerialNumber = JSON.parse(this.total_received_product_array[j].SerialNumber);
            }
            // this.total_received_product_array[j].ReturnQty = 0;
            // this.total_received_product_array[j].ReturnSN = '';
            // this.total_received_product_array[j].ReturnSNArray = [];
            for(let k=0; k<this.total_received_product_array[j].Taxes.length; k++){
              delete this.total_received_product_array[j].Taxes[k].IDn;
            }
          }
        } else {
          // If there are more than one RE...
          for(let y=0; y<this.cur_po_receive_completedOnly[i].Products.length; y++){
            for(let x=0; x<this.total_received_product_array.length; x++){
              if(this.cur_po_receive_completedOnly[i].Products[y].POProductIDn == this.total_received_product_array[x].POProductIDn){
                // Found corresponding POProductIDn

                // Increment TotalCost, TotalTax, Total, ReceivedQty of each product
                this.total_received_product_array[x].ReceivedQty = (Number(this.total_received_product_array[x].ReceivedQty) + Number(this.cur_po_receive_completedOnly[i].Products[y].ReceivedQty)).toFixed(2);
                this.total_received_product_array[x].TotalCost = (Number(this.total_received_product_array[x].TotalCost) + Number(this.cur_po_receive_completedOnly[i].Products[y].TotalCost)).toFixed(2);
                this.total_received_product_array[x].TotalTax = (Number(this.total_received_product_array[x].TotalTax) + Number(this.cur_po_receive_completedOnly[i].Products[y].TotalTax)).toFixed(2);
                this.total_received_product_array[x].Total = (Number(this.total_received_product_array[x].Total) + Number(this.cur_po_receive_completedOnly[i].Products[y].Total)).toFixed(2);
                // this.total_received_product_array[x].ReturnQty = 0;
                // this.total_received_product_array[x].ReturnSN = '';
                // this.total_received_product_array[x].ReturnSNArray = [];

                // Concat Serial Number of each product
                if(this.total_received_product_array[x].SerialNumber && this.cur_po_receive_completedOnly[i].Products[y].SerialNumber){
                  this.total_received_product_array[x].SerialNumber = this.total_received_product_array[x].SerialNumber.concat(JSON.parse(this.cur_po_receive_completedOnly[i].Products[y].SerialNumber));
                }
                
                // Calculate taxes
                for(let m=0; m<this.total_received_product_array[x].Taxes.length; m++){
                  for(let n=0; n<this.cur_po_receive_completedOnly[i].Products[y].Taxes.length; n++){
                    if(this.total_received_product_array[x].Taxes[m].TaxID == this.cur_po_receive_completedOnly[i].Products[y].Taxes[n].TaxID){
                      if(this.total_received_product_array[x].Taxes[m].TaxType == '$'){
                        this.total_received_product_array[x].Taxes[m].TaxAmount = (Number(this.total_received_product_array[x].Taxes[m].TaxValue) * Number(this.total_received_product_array[x].ReceivedQty)).toFixed(2);
                      } else {
                        this.total_received_product_array[x].Taxes[m].TaxAmount = (Number(this.total_received_product_array[x].Taxes[m].TaxValue) * Number(this.total_received_product_array[x].ReceivedQty) * 
                                                                             Number(this.total_received_product_array[x].UnitCost) * 0.01 ).toFixed(2);
                      }
                    }
                  }
                }
              } else {
                // No corresponding POProductIDn. User added new items to PO after first receiving
                // TODO: create new entry in total_received_product_array[]
              }
            }
          }
        }
      }
      // console.log("@set_total_received_product_array() total_received_product_array:", this.total_received_product_array);
    },
    async get_init_data() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }
      if (this.cur_date_type) {
        bodyFormData.append("cur_date_type", this.cur_date_type);
      }
      if (this.cur_vendor_id) {
        bodyFormData.append("VendorIDn", this.cur_vendor_id);
      }
      if (this.cur_order_no) {
        bodyFormData.append("OrderID", this.cur_order_no);
      }
      if (this.cur_invoice_no) {
        bodyFormData.append("InvoiceNo", this.cur_invoice_no);
      }
      if (this.cur_remarks) {
        bodyFormData.append("Remarks", this.cur_remarks);
      }
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_user_idn) {
        bodyFormData.append("CreateByIDn", this.cur_user_idn);
      }
      bodyFormData.append("Status", this.activeName);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_list = rt.data.list_list;
              that.cur_po_meta = rt.data.list_meta;
              if (that.cur_po_meta.cnt != undefined) {
                that.page_total = that.cur_po_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              that.vendor_list = rt.data.vendor_list;
              that.create_vendor_datalist();
              that.pay_period_list = rt.data.pay_period_list;
              that.carrier_list = rt.data.carrier_list;
              that.carrier_names = rt.data.carrier_names;
              that.full_product_list = rt.data.idxproducts;
              that.store_list = rt.data.store_list;
              // Add an empty element to the end of array, then push the empty element to the front
              that.store_list[that.store_list.length] = {"LocationID": "", "LocationName": ""};
              var temp = JSON.parse(JSON.stringify(that.store_list));
              for(var i = 0; i < that.store_list.length; i++){
                if(i == 0){
                  that.store_list[i] = temp[that.store_list.length - 1];
                } else {
                  that.store_list[i] = temp[i - 1];
                }
              }
              that.sales_list = rt.data.sales_list;
              that.country_list = rt.data.country_list;
              that.province_list = rt.data.province_list;
              // console.log(rt.data.province_list);
              that.city_list = rt.data.city_list;
              that.po_show_name2 = rt.data.po_show_name2;
              that.loadingTabContent = false;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_extra() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData.append("POIDn", this.cur_po.POIDn);
      bodyFormData.append("LocationID", this.cur_po.ShipTLocationID);
      bodyFormData.append("CustomerIDn", this.cur_po.VendorIDn);

      this.loaded_return_data = false;
      this.loaded_receive_data = false;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/extra",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_tax = rt.data.po_tax;
              that.cur_po_product = rt.data.po_product;
              that.cur_po_product_old = JSON.parse(JSON.stringify(rt.data.po_product));
              that.cur_po_receive = rt.data.po_receive;
              that.getCompletedRE(that.cur_po_receive);
              that.set_total_received_product_array();
              that.cur_po_return = rt.data.po_return;
              that.inventory_products = rt.data.inventory_products;
              that.$nextTick(() => {
                if (that.cur_po_return.length > 0) {
                  that.loaded_return_data = true;
                }
                if (that.cur_po_receive.length > 0) {
                  that.loaded_receive_data = true;
                  // When viewing "Complete" PO_Status, Remove "Draft" RE from cur_po_receive
                  if(that.PO_status == 'Complete'){
                    for (var i = that.cur_po_receive.length - 1; i >= 0; i--) {
                      // Auction.auctions[i].seconds--;
                      if(that.cur_po_receive[i].Status == 'Draft'){
                        that.cur_po_receive.splice(i, 1);
                      }
                    }
                  }
                }
              });
              that.get_hasSerialNumber();
              that.set_hasSerialNumber();
              that.calculatePendingQty();
              that.set_ReceivedQty();
              that.add_cur_product_qty();
              that.cur_po_payment = rt.data.po_payment;
              that.cur_po_credit = rt.data.po_credit;
              that.cur_outbound = rt.data.outbound;
              that.cur_inbound = rt.data.inbound;
              that.payment_method_list = rt.data.payment_method_list;
              that.payment_type_list = rt.data.payment_type_list;
              that.credit_type_list = rt.data.credit_type_list;
              that.vendor_credit = rt.data.vendor_credit;
              that.all_SN_product_array = rt.data.all_SN_products;
              that.loadingReceive = false;
              // console.log("@get_extra() all_SN_product_array:", that.all_SN_product_array);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
        this.set_cascader_options();
        this.filtercurpo
    },
    autoCompleteRE(){
      let allReceived = true;
      for(var i=0; i<this.cur_po_product.length; i++){
        if(parseFloat(this.cur_po_product[i].PendingQty) > 0){
          allReceived = false;
          break;
        }
      }
      // Check this.cur_po.Status !== 'Complete', else infinite loop
      if(allReceived && this.cur_po.Status !== 'Complete'){
        this.submit_click('Complete');
      } 
      else if(!allReceived && this.cur_po.Status !== 'Pending') {
        this.submit_click('Pending');
      }
    },
    async post_po(to_status, RE_Status, receive_idn) {
      var bodyFormData = new FormData();
      if (!this.cur_po.Status) this.cur_po.Status = "Draft";
      let from_status = JSON.parse(JSON.stringify(this.cur_po.Status));
      this.cur_po.Status = to_status;
      this.show_listed_products = false;
      this.encode_container_string();
      this.update_po_product_tax();
      this.update_re_tax();
      this.update_re();
      this.update_re_product();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("po_from_status", from_status);
      if(RE_Status && RE_Status!='SaveChange'){
        bodyFormData.append("po", JSON.stringify(this.cur_re));
        bodyFormData.append("po_tax", JSON.stringify(this.cur_re_tax));
        bodyFormData.append("po_product", JSON.stringify(this.cur_po_product));
      } else {
        bodyFormData.append("po", JSON.stringify(this.cur_po));
        bodyFormData.append("po_tax", JSON.stringify(this.cur_po_tax));
        bodyFormData.append("po_product", JSON.stringify(this.cur_po_product_old));
      }
      // bodyFormData.append("po_product", JSON.stringify(this.cur_po_product));
      // bodyFormData.append("re_product", JSON.stringify(this.cur_re_product));
      bodyFormData.append("re_status", RE_Status);
      bodyFormData.append("receive_idn", receive_idn);
      if(RE_Status === 'EditCompleted'){
        bodyFormData.append("po_product_old", JSON.stringify(this.po_product_old));
      }

      // Display the key/value pairs
      // console.log("@post_po() bodyFormData:");
      // console.log("------------------------------------------");
      for (var pair of bodyFormData.entries()) {
          // console.log(pair[0]+ ', ' + pair[1]); 
      }
      // console.log("------------------------------------------");

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.to_status != undefined) {
                that.activeName = rt.data.to_status;
              }
              that.cur_po_list = rt.data.to_list;
              that.cur_po_meta = rt.data.to_meta;
              if (that.cur_po_meta.cnt != undefined) {
                that.page_total = that.cur_po_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              if ( that.cur_is_return || that.cur_is_receive) {
                that.cur_is_return = false;
                that.cur_is_receive = false;
                that.cur_po.Status = from_status;
                that.set_edit_po();
              }
              else {
                if(RE_Status != 'SaveChange'){
                  that.edit_po(that.cur_po, 'Pending');
                  that.currpage = "edit";
                } else {
                  that.currpage = "list";
                }
              }
              // console.log("@post_po() /Api/Web/Purchase/PO/update response", rt);
              if(to_status != 'Complete'){
                that.autoCompleteRE();
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              that.cur_is_return = false;
              that.cur_is_receive = false;
              that.currpage = "list";
              alert(rt.message);
            }
            that.show_listed_products = true;
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    print_order(type) {
      var url = this.apiBaseUrl + "/Web/Purchase/Pdf?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&type=" + type;
      url += "&POIDn=" + this.cur_po.POIDn;
      if(type == 'PR'){
        url += "&PRIDn=" + this.PR_IDn;
      }
      else if(type == 'RE'){
        url += "&REIDn=" + this.RE_idn;
      }
      window.open(url, "_blank");
    },
    update_po_info_delay() {
      if (this.po_delay_timer) {
        clearTimeout(this.po_delay_timer);
        this.po_delay_timer = null;
      }
      var that = this
      this.po_delay_timer = setTimeout(() => {
        that.update_po_info(1);
      }, 400);
    },
    update_po_info(mode = 0) {
      // this.update_po_product_tax();
      // this.update_po_tax();
      // this.update_po();
      var bodyFormData = new FormData();
      if (!this.cur_po.Status) this.cur_po.Status = "Draft";
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("POIDn", this.cur_po.POIDn);
      bodyFormData.append("VendorIDn", this.cur_po.VendorIDn);
      bodyFormData.append("LocationID", this.cur_po.LocationID);
      bodyFormData.append("CountryIDn", this.cur_po.ComCountryIDn);
      bodyFormData.append("ProvinceIDn", this.cur_po.ComProvinceIDn);
      bodyFormData.append("po_product", JSON.stringify(this.cur_po_product));
      bodyFormData.append("Mode", mode);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Purchase/PO/update_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_po_product = rt.data.po_product;
              if(mode == 0){
                for(var i=0; i<that.cur_po_product.length; i++){
                  delete that.cur_po_product[i].JustAdded;
                }
              }
              for(var i=0; i<that.cur_po_product.length; i++){
                that.cur_po_product[i].UnitCost = parseFloat(that.cur_po_product[i].UnitCost).toFixed(2);
                that.cur_po_product[i].TotalCost = parseFloat(that.cur_po_product[i].TotalCost).toFixed(2);
              }
              // console.log("@update_po_info() /Api/Web/Purchase/PO/update_product rt.data.po_tax:", rt.data.po_tax);
              that.cur_po_tax = rt.data.po_tax;
              that.update_po();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    update_re_tax(){
      this.cur_re_tax = JSON.parse(JSON.stringify(this.cur_po_tax));
      for(let p=0; p<this.cur_re_tax.length; p++){
        this.cur_re_tax[p].TaxAmount = 0;
      }

      for(var i=0; i<this.cur_po_product.length; i++){
        for(var j=0; j<this.cur_po_product[i].Taxes.length; j++){
          for(var k=0; k<this.cur_re_tax.length; k++){
            if(this.cur_po_product[i].Taxes[j].TaxID == this.cur_re_tax[k].TaxID){
              this.cur_re_tax[k].TaxAmount = (Number(this.cur_re_tax[k].TaxAmount) + Number(this.cur_po_product[i].Taxes[j].TaxAmount)).toFixed(2);
            }
          }
        }
      }
    },
    update_re(){
      this.cur_re = JSON.parse(JSON.stringify(this.cur_po));
      
      var temp_SubTotal = 0;
      for(var i=0; i<this.cur_po_product.length; i++){
        temp_SubTotal = temp_SubTotal + Number(this.cur_po_product[i].TotalCost);
      }
      this.cur_re.SubTotal = Number(temp_SubTotal).toFixed(2);

      var temp_TotalTax = 0;
      for(var i=0; i<this.cur_re_tax.length; i++){
        temp_TotalTax = temp_TotalTax + Number(this.cur_re_tax[i].TaxAmount);
      }
      this.cur_re.TotalTax = Number(temp_TotalTax).toFixed(2);

      this.cur_re.TotalAmount = (Number(this.cur_re.SubTotal) + Number(this.cur_re.TotalTax)).toFixed(2);

    },
    update_re_product(){
      this.cur_re_product = JSON.parse(JSON.stringify(this.cur_po_product));
    },

    update_po_product_tax() {
      for (let i=0; i<this.cur_po_product.length; i++) {

        let temp_TotalTax = 0;
        // Update cur_po_product[i].Taxes
        for(var j=0; j<this.cur_po_product[i].Taxes.length; j++){
          let cur_tax = this.cur_po_product[i].Taxes[j];
          cur_tax.TaxAmount = 0;
          if(cur_tax.ValueType == "$") {
            cur_tax.TaxAmount = ( parseFloat(cur_tax.TaxValue) * parseFloat(this.cur_po_product[i].ReceivedQty) ).toFixed(2) || "0.00";
          } else {
            cur_tax.TaxAmount =( parseFloat(cur_tax.TaxValue) * parseFloat(this.cur_po_product[i].UnitCost) * parseFloat(this.cur_po_product[i].ReceivedQty) * 0.01 ).toFixed(2) || "0.00";
          }
          temp_TotalTax = Number(temp_TotalTax) + Number(cur_tax.TaxAmount);
        }

        this.cur_po_product[i].TotalTax = parseFloat(temp_TotalTax).toFixed(2);

        this.cur_po_product[i].TotalCost = (parseFloat(this.cur_po_product[i].UnitCost) * parseFloat(this.cur_po_product[i].ReceivedQty)).toFixed(2) || "0.00";
        
        this.cur_po_product[i].Total = (parseFloat(this.cur_po_product[i].TotalTax) + parseFloat(this.cur_po_product[i].TotalCost)).toFixed(2) || "0.00";
        
      }
    },
    update_po() {
      this.cur_po.SubTotal = 0;
      this.cur_po.TotalTax = 0;
      this.cur_po.TotalAmount = 0;
      this.cur_po.Discount = 0;
      for (let k in this.cur_po_product) {
        this.cur_po.SubTotal += Number(this.cur_po_product[k]["TotalCost"]) || 0;
        // if (this.cur_po_product[k].OrderQty) {
        //   this.cur_po.Discount += (parseFloat(this.cur_po_product[k].OriginalPrice) - parseFloat(this.cur_po_product[k].UnitCost)) * parseFloat(this.cur_po_product[k].OrderQty);
        // }
      }
      this.cur_po.Discount = parseFloat(this.cur_po.Discount).toFixed(2);
      for (let k in this.cur_po_tax) {
        this.cur_po.TotalTax += Number(this.cur_po_tax[k]["TaxAmount"]) || 0;
      }
      this.cur_po.SubTotal = this.cur_po.SubTotal.toFixed(2) || "0.00";
      this.cur_po.TotalTax = this.cur_po.TotalTax.toFixed(2) || "0.00";
      this.cur_po.TotalAmount = (
        (Number(this.cur_po.SubTotal) || 0) +
        (Number(this.cur_po.TotalTax) || 0) +
        (Number(this.cur_po.ShipFee) || 0) +
        (Number(this.cur_po.OtherFees) || 0)
      ).toFixed(2);

      if (this.cur_discountRate > 0) {
        this.cur_po.SubTotal = (this.cur_po.SubTotal - (this.cur_po.SubTotal * this.cur_discountRate / 100)).toFixed(2);
        this.cur_po.TotalTax = (this.cur_po.TotalTax - (this.cur_po.TotalTax * this.cur_discountRate / 100)).toFixed(2);
        this.cur_po.TotalAmount = (this.cur_po.TotalAmount - (this.cur_po.TotalAmount * this.cur_discountRate / 100)).toFixed(2);
      }
    },
    changed_province(type) {
      if(type == 0) {
        if (this.cur_provincelist.length <= 0) {
          return;
        }
        if (
          !this.cur_address.ProvinceIDn ||
          this.cur_address.ProvinceIDn == undefined ||
          this.cur_address.ProvinceIDn <= 0
        ) {
          this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
        }
        this.cur_address.AddressProvince = "";
        for (var i = 0; i < this.cur_provincelist.length; i++) {
          if (this.cur_provincelist[i].IDn == this.cur_address.ProvinceIDn) {
            this.cur_address.AddressProvince = this.cur_provincelist[i].Province;
          }
        }
        if (this.cur_address.AddressProvince == "") {
          // Didn't find Province
          this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
        }
      }
    },
    changed_country(countryIDn, type) {
      var tempCountryIDn = countryIDn;
      // type 0: this.cur_address.CountryIDn
      // type 1: cur_po.PurchaseFCountryIDn Vendor
      // type 2: cur_po.ComCountryIDn Billing Address
      // type 3: cur_po.ShipTCountryIDn Shipping Address
      if ( !tempCountryIDn ||
           tempCountryIDn == undefined ||
           tempCountryIDn <= 0)
      {
        tempCountryIDn = 1;     // Set default to Canada
        if( type == 1){
          this.cur_po.PurchaseFCountryIDn = tempCountryIDn;
        }  else if( type == 2){
          this.cur_po.ComCountryIDn = tempCountryIDn;
        }  else if( type == 3){
          this.cur_po.ShipTCountryIDn = tempCountryIDn;
        } 
      }
      // if(  type == 0 ){
      //   for (var idx in this.country_list) {
      //     if (this.country_list[idx].IDn == tempCountryIDn) {
      //       this.cur_address.AddressCountry = this.country_list[idx].Country;
      //     }
      //   }
      // }
      var temp_provincelist = [];
      for (var idx in this.province_list) {
        if (this.province_list[idx].CountryIDn == tempCountryIDn) {
          temp_provincelist.push(this.province_list[idx]);
        }
      }

      if( type == 1){                                                     // When country is changed @choosing vendor
        this.cur_vendor_provincelist = temp_provincelist;                 // Update specific province list
        if(this.cur_po.PurchaseFAddressIDn == 0){                         // If no address given
          if(tempCountryIDn == 1){                                        // If country is CA,
            this.cur_po.PurchaseFProvinceIDn = temp_provincelist[1].IDn;  // Set province to be BC
          } else {                                                        // If country is others,
            this.cur_po.PurchaseFProvinceIDn = temp_provincelist[0].IDn;  // Set province to be the first element
          }
        }   // ------------------------------------------------------------------------------------------------------------------
      }  else if( type == 2){                                             // When country is changed @choosing billing address
        this.cur_billing_provincelist = temp_provincelist;                // Update specific province list
        if(tempCountryIDn == 1){                                          // If country is CA,
          this.cur_po.ComProvinceIDn = temp_provincelist[1].IDn;          // Set province to be BC
        } else {                                                          // If country is others,
          this.cur_po.ComProvinceIDn = temp_provincelist[0].IDn;          // Set province to be the first element
        }   // ------------------------------------------------------------------------------------------------------------------
      }  else if( type == 3){                                             // When country is changed @choosing shipping address
        this.cur_shipping_provincelist = temp_provincelist;               // Update specific province list
        if(tempCountryIDn == 1){                                          // If country is CA,
          this.cur_po.ShipTProvinceIDn = temp_provincelist[1].IDn;        // Set province to be BC
        } else {                                                          // If country is others,
          this.cur_po.ShipTProvinceIDn = temp_provincelist[0].IDn;        // Set province to be the first element
        }   // ------------------------------------------------------------------------------------------------------------------
      } else if( type == 4){                                              // When country is changed @creating new Vendor
        this.new_vendor_provincelist = temp_provincelist;                 // Update specific province list       
        if(tempCountryIDn == 1){                                          // If country is CA,
          this.new_vendor.ProvinceIDn = temp_provincelist[1].IDn;         // Set province to be BC
        } else {                                                          // If country is others,
          this.new_vendor.ProvinceIDn = temp_provincelist[0].IDn;         // Set province to be the first element
        } 
      }
      this.changed_province(type);
    },    
    init_receive(mode, order) {
      this.receive_mode = mode;
      this.isUnlocked = false;
      this.cur_po_receive_cur_row = order;
      if(mode === 'complete'){
        this.re_complete_data_array = order.Products;
        this.re_edit_complete_data_array = JSON.parse(JSON.stringify(this.cur_po_product));
        this.po_product_old = JSON.parse(JSON.stringify(this.cur_po_product));
        for(var i=0; i<this.re_edit_complete_data_array.length; i++){
          for(var j=0; j<order.Products.length; j++){
            if(this.re_edit_complete_data_array[i].IDn == order.Products[j].POProductIDn){
              this.re_edit_complete_data_array[i].PendingQty = Number(order.Products[j].ReceivedQty) + Number(this.re_edit_complete_data_array[i].PendingQty);
              this.re_edit_complete_data_array[i].PendingQty = parseFloat(this.re_edit_complete_data_array[i].PendingQty).toFixed(2);
              this.re_edit_complete_data_array[i].ReceivedQty = order.Products[j].ReceivedQty;
              this.re_edit_complete_data_array[i].SerialNumber = order.Products[j].SerialNumber;
              this.re_edit_complete_data_array[i].ReceivedProductIDn = order.Products[j].IDn
            }
          }
        }
        this.cur_RE_orderNo = order.OrderID;
        this.cur_RE_status = order.Status;
        this.RE_idn = order.IDn;
      }
      else if(mode === 'edit'){
        this.re_draft_data_array = JSON.parse(JSON.stringify(this.cur_po_product));
        for(var i=0; i<this.re_draft_data_array.length; i++){
          for(var j=0; j<order.Products.length; j++){
            if(this.re_draft_data_array[i].IDn == order.Products[j].POProductIDn){
              this.re_draft_data_array[i].ReceivedQty = order.Products[j].ReceivedQty;
              this.re_draft_data_array[i].SerialNumber = order.Products[j].SerialNumber;
              this.re_draft_data_array[i].ReceivedProductIDn = order.Products[j].IDn
            }
          }
        }
        this.cur_po_product = this.re_draft_data_array;
        this.cur_RE_orderNo = order.OrderID;
        this.cur_RE_status = order.Status;
        this.RE_idn = order.IDn;
      }
      else if(mode === 'new'){
        this.set_ReceivedQty();   // Set ReceivedQty to be equal to PendingQty
      }
      this.$refs["add-receive-modal"].show();
    },
    setStartandEndDate() {
      // Get current time
      var today = new Date();
      // var month = 5;
      // today.setFullYear(2020, month-1, 31);
      // Get Current day, month, year
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //Jan: mm="01"    Dec: mm="12"
      var yyyy = today.getFullYear();
      // Get 1 month ago day, month, year
      var dd_1_Month_Ago = dd;
      var mm_1_Month_Ago;
      var yyyy_1_Month_Ago = yyyy;
      // Case 1: January now!
      if(mm === "01"){
        mm_1_Month_Ago = "12";  // Set month to be Dec
        yyyy_1_Month_Ago = parseFloat(yyyy) - 1; // Set year to be last year
      }
      // Case 2: March now!
      else if(mm === "03"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is March 29/30/31, 1 month ago is Feb 28/29, depends on if leap year
        if(parseFloat(dd) > 28){
          if(((0 == parseFloat(yyyy) % 4) && (0 != parseFloat(yyyy) % 100) || (0 == parseFloat(yyyy) % 400))){
            dd_1_Month_Ago = "29";
          } else {
            dd_1_Month_Ago = "28";
          }
        }
      }
      // Case 3: Feb, Apr, June, Sept or Nov now!
      else if(mm === "02" || mm === "04" || mm === "06" || mm === "09" || mm === "11"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month
      }
      // Case 4: May, July, Aug, Oct or Dec now!
      else {
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is May 31, 1 month ago is Apr 30
        if(parseFloat(dd) > 30){
          dd_1_Month_Ago = "30";
        }
      }

      // Set Start Date and End Date
      this.cur_end_dt = yyyy + '-' + mm + '-' + dd;
      this.cur_start_dt = yyyy_1_Month_Ago + '-' + mm_1_Month_Ago + '-' + dd_1_Month_Ago;
    },
    calculate_el_select_width(){
      for(var i = 0; i < document.getElementsByClassName("el-dropdown").length; i++){
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.borderRadius = "0.375rem";
        document.getElementsByClassName("el-dropdown")[i].style.width = document.getElementsByClassName("el-dropdown")[0].parentElement.clientWidth + "px";
        document.getElementsByClassName("el-dropdown")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown")[i].style.width) - 10) + 'px'; // -10 because el-row gutter is 10px
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.height = "43px";
      }
    },
    calculate_el_select_width2(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown2").length; i++){
          document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.borderRadius = "0.375rem";
          document.getElementsByClassName("el-dropdown2")[i].style.width = document.getElementsByClassName("form-control")[0].clientWidth + "px";
          document.getElementsByClassName("el-dropdown2")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown2")[i].style.width) + 2.06) + 'px';
          document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.height = "43px";
        }
        for(var i = 0; i < document.getElementsByClassName("el-dropdown3").length; i++){
          document.getElementsByClassName("el-dropdown3")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown3")[i].style.width = document.getElementsByClassName("fred2")[0].clientWidth + "px";
          document.getElementsByClassName("el-dropdown3")[i].children[0].children[0].style.height = "46px";
        }
        for(var i = 0; i < document.getElementsByClassName("el-dropdown4").length; i++){
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown4")[i].style.width = (parseFloat(document.getElementsByClassName("fred3")[0].clientWidth) * 1.20) + "px";
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.height = "46px";
        }
        for(var i = 0; i < document.getElementsByClassName("fred").length; i++){
          document.getElementsByClassName("fred")[i].style.width = document.getElementsByClassName("form-control")[0].clientWidth + "px";
          document.getElementsByClassName("fred")[i].style.width = (parseFloat(document.getElementsByClassName("fred")[i].style.width) + 2.06) + 'px';
        }
      },1);
    },
    reset_search_parameters(){
      this.pro_search_plu = "";
      this.pro_search_name = "";
      this.pro_search_description = "";
      this.pro_search_unit = "";
      this.pro_search_qty = "";
      this.pro_search_onhold = "";
      this.pro_search_onorder = "";
    },
    set_cascader_options(){
      var department = {};
      var category = {};
      var product = {};
      if(this.cascader_options.length == 0){
        for(let i=0; i<this.full_product_list.length; i++){
          var department_children = [];
          department = {"value": i, "label": this.full_product_list[i].Name1, "children": [] };
          for(let j=0; j<this.full_product_list[i].Categorys.length; j++){
            var category_children = [];
            category = {"value": j, "label": this.full_product_list[i].Categorys[j].Name1, "children": [] };
            for(let k=0; k<this.full_product_list[i].Categorys[j].Products.length; k++){
              product = {"value": k, "label": this.full_product_list[i].Categorys[j].Products[k].Name1};
              category_children.push(product);
            }
            category.children = category_children;
            department_children.push(category);
          }
          department.children = department_children;
          this.cascader_options.push(department);
        }
      }
    },
    initSN_data(product) {
      this.cur_po_product_cur_row = product;
      this.input_SN = "";
      this.inputted_SN_array = [];
      // console.log("product", product);
      // product is cur_po_product[i]
      // product.SerialNumber is defined only after submitSN
      //if(this.sn_mode!='new'){
        if(product.SerialNumber){
          if(Array.isArray(product.SerialNumber)){
            this.saved_SN_array = product.SerialNumber;
          } else {
            this.saved_SN_array = JSON.parse(product.SerialNumber);
          }
        } else {
          this.saved_SN_array = [];
        }
      // } else {
      //   this.saved_SN_array = [];
      // }

      // Update display_SN_array
      this.display_SN_array = this.saved_SN_array.concat(this.inputted_SN_array);
    },
    selectSN(row, mode){
      this.sn_mode = mode;
      if(this.sn_mode === 'new'){
        // if(!this.validateReceivedQty()) return;
      }

      this.format_ReceivedQty();  // Make each ReceivedQty of cur_po_product[i] 2 decimal places
      this.initSN_data(row);
      this.$refs["select-sn-modal"].show();
    },
    addSN_enter_keydown_handler(){
      this.addSN();
    },
    addSN() {
      // Check if S/N exist in database
      // console.log("input_SN", this.input_SN);
      // console.log("all_SN_product_array", this.all_SN_product_array);
      // console.log("cur_po_product_cur_row", this.cur_po_product_cur_row);
      if(this.isUnlocked){
        if(!JSON.parse(this.cur_po_product_cur_row.SerialNumber).includes(this.input_SN)){
          for(let i=0; i<this.all_SN_product_array.length; i++){
            if(this.all_SN_product_array[i].SN == this.input_SN){
              if(this.all_SN_product_array[i].Status == 'Active' || this.all_SN_product_array[i].Status == 'Delete'){
                alert("This S/N exists in database.\n\nReference:\nSN: " + this.all_SN_product_array[i].SN
                            + "\nIDn: " + this.all_SN_product_array[i].IDn
                            + "\nProductIDn: " + this.all_SN_product_array[i].ProductIDn
                            + "\nStatus: " + this.all_SN_product_array[i].Status);
                return;
              } 
            }
          }
        }
      }
      
      // Check if S/N already used
      if(this.display_SN_array.includes(this.input_SN)) {
        alert("This S/N is already used.");
        return;
      }

      // Check if exceed limit
      if(this.display_SN_array.length >= this.cur_po_product_cur_row.ReceivedQty){
        alert("You cannot add S/N more than what you are receiving. Error Code: 111");
        return;
      }

      // Push to inputted_SN_array
      this.inputted_SN_array.push(this.input_SN);

      // Update display_SN_array
      this.display_SN_array = this.saved_SN_array.concat(this.inputted_SN_array);

      // Reset input_SN
      this.input_SN = '';
    },
    removeSN(row){

      // Alert message if S/N has been returned
      // console.log("all_SN_product_array", this.all_SN_product_array);
      // console.log("row", row);
      let msg = "";
      for(let i=0; i<this.all_SN_product_array.length; i++){
        if(this.all_SN_product_array[i].SN === row && this.all_SN_product_array[i].Status === 'Delete'){
          msg = this.all_SN_product_array[i].SN + " on " + (this.all_SN_product_array[i].UpdateDateTime).substring(0,19);
          alert("You have returned " + msg + ".\n\nYou cannot remove this S/N from the list.");
          return;
        }
      }

      // Remove from saved_SN_array || inputted_SN_array
      let index = this.saved_SN_array.indexOf(row);
      if (index > -1) {
        this.saved_SN_array.splice(index, 1); 
      } else {
        index = this.inputted_SN_array.indexOf(row);
        if (index > -1) {
          this.inputted_SN_array.splice(index, 1); 
        }
      }

      // Update display_SN_array
      this.display_SN_array = this.saved_SN_array.concat(this.inputted_SN_array);
    },
    submitSN(mode, state){
      
      // console.log("mode: ", mode);
      // console.log("state: ", state);

      if(mode=='complete' && state == true){
        // Update re_edit_complete_data_array Serial Number
        for(var i=0; i<this.re_edit_complete_data_array.length; i++){
          if(this.re_edit_complete_data_array[i].IDn === this.cur_po_product_cur_row.IDn){
            this.re_edit_complete_data_array[i].SerialNumber = JSON.stringify(this.display_SN_array);
          }
        }
        // console.log("Successfully updated re_edit_complete_data_array:", this.re_edit_complete_data_array);
      } 
      else if(mode=='edit' && state == false){
        // Update re_draft_data_array Serial Number
        for(var i=0; i<this.re_draft_data_array.length; i++){
          if(this.re_draft_data_array[i].IDn === this.cur_po_product_cur_row.IDn){
            if(this.display_SN_array == []){
              this.re_draft_data_array[i].SerialNumber = "[]";
            } else {
              this.re_draft_data_array[i].SerialNumber = JSON.stringify(this.display_SN_array);
            }
          }
        }
        // console.log("Successfully updated re_draft_data_array:", this.re_draft_data_array);
      }
      else {
        // Update cur_po_product Serial Number
        for(var i=0; i<this.cur_po_product.length; i++){
          if(this.cur_po_product[i].IDn === this.cur_po_product_cur_row.IDn){
            if(this.display_SN_array == []){
              this.cur_po_product[i].SerialNumber = "[]";
            } else {
              this.cur_po_product[i].SerialNumber = JSON.stringify(this.display_SN_array);
            }
          }
        }
        
        // console.log("Successfully updated cur_po_product:", this.cur_po_product);
      }

      this.$refs["select-sn-modal"].hide();
    },
    resetSN_row(product){
      for(var i=0; i<this.cur_po_product.length; i++){
        if(this.cur_po_product[i].IDn === product.IDn){
          this.cur_po_product[i].SerialNumber = "";
        }
      }
    },
    resetSN_all(){
      for(var i=0; i<this.cur_po_product.length; i++){
        this.cur_po_product[i].SerialNumber = "";
      }
    },
    close_select_sn_model() {
      this.$refs["select-sn-modal"].hide();
    },
    format_ReceivedQty(){
      for(var i=0; i<this.cur_po_product.length; i++){
        if(this.cur_po_product[i].ReceivedQty === "") {
          this.cur_po_product[i].ReceivedQty=parseFloat(0).toFixed(2);
        } else {
          this.cur_po_product[i].ReceivedQty=parseFloat(this.cur_po_product[i].ReceivedQty).toFixed(2);
        }
      }
      for(var i=0; i<this.re_edit_complete_data_array.length; i++){
        if(this.re_edit_complete_data_array[i].ReceivedQty === "") {
          this.re_edit_complete_data_array[i].ReceivedQty=parseFloat(0).toFixed(2);
        } else {
          this.re_edit_complete_data_array[i].ReceivedQty=parseFloat(this.re_edit_complete_data_array[i].ReceivedQty).toFixed(2);
        }
      }
    },
    updateReceivedQty(product){
      for(var i=0; i<this.cur_po_product.length; i++){
        if(this.cur_po_product[i].IDn === product.IDn){
          this.cur_po_product[i].ReceivedQty = product.ReceivedQty;
        }
      }
    },
    unlockOrder(){
      this.isUnlocked = true;
      this.po_product_old = JSON.parse(JSON.stringify(this.cur_po_product));

      // Initialize OrderedQty to be 0 
      for(var i=0; i<this.po_product_old.length; i++){
        this.po_product_old[i].OrderedQty = 0;
      }

      // For each item in po_product_old, set "OrderedQty" to be equal to the "ReceivedQty" of each item in the current RE
      for(var i=0; i<this.po_product_old.length; i++){
        for(var j=0; j<this.cur_po_receive_cur_row.Products.length; j++){
          if(this.cur_po_receive_cur_row.Products[j].POProductIDn === this.po_product_old[i].IDn){
            this.po_product_old[i].OrderedQty = this.cur_po_receive_cur_row.Products[j].ReceivedQty;
            this.po_product_old[i].SerialNumber = this.cur_po_receive_cur_row.Products[j].SerialNumber;
          }
        }
      }
    },
    
  },
  watch:{
    'cur_po.PayPeriodIDn': function(val){
      this.cur_po.PayPeriodIDn = Number(val);
    },
    deep: true,
    'cur_po.CurrencyIDn': function(val){
      this.cur_po.CurrencyIDn = Number(val);
    },
    deep: true,
    "$data.cur_vendor_name": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.PurchaseFCity": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.LocationID": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.ComCity": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.ShipTLocationID": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
    "$data.cur_po.ShipTCity": {
      handler: function(newValue){
        this.calculate_el_select_width2();
      },
      deep: true,
    },
  },
  mounted() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
    this.get_init_data();
    this.setStartandEndDate();
    setTimeout(()=>{
      this.calculate_el_select_width()
    },50); // timer is used to avoid funciton call before left menu is loaded
    window.addEventListener('resize', this.calculate_el_select_width);
    window.addEventListener('resize', this.calculate_el_select_width2);
    this.checkLoadingState("loadingTabContent");
  },
  unmounted(){
    window.removeEventListener('resize', this.calculate_el_select_width);
    window.removeEventListener('resize', this.calculate_el_select_width2);
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
hr.solid {
  border-top: 1px solid #bbb;
}
.input-label {
  display: inline-block;
  width: 130px;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
  border: 3px solid rgb(165, 209, 250);
}
.el-collapse-item__wrap {
  display: contents;
  content: "";
  clear: both;
  width: calc(100% - 5px);
  overflow-y: visible;
}
.addressinfo .el-collapse-item__content,
.productlist .el-collapse-item__content,
.podetail .el-collapse-item__content {
  padding: 0;
}
.product_popup {
  padding: 2%;
  position: fixed;
  top: calc(50% - 21rem);
  left: 15%;
  height: 42rem;
  width: 70%;
  z-index: 10;
  background: white;
}
.group-append-text .input-group-text {
  flex: 1;
}

.product-table th, .product-table td {
  padding: 0.1rem !important;
}
.product-table th div, .product-table td div {
  padding: 0.1rem !important;
  line-height: 1.25rem;
  overflow: visible;
}

#search_modal .modal-title,#import_modal .modal-title {
  font-size: 1.5rem;
}
#search_modal hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search_product_section {
  max-height: 22rem !important;
  overflow: auto;
}
.search_product_section .el-table td,
.el-table th {
  padding: 2px 0;
}
.product_row {
  border-top: 1px solid gray;
  padding-top: 5px;
}
.product_sn {
  max-width: 10rem;
  display: inline-flex;
}
.product_sn_col {
  max-height: 4rem;
  overflow: auto;
}
.btn-inner--icon .icon-add {
  color: green;
  font-size: 1.8rem;
}
.btn-inner--icon .icon-remove {
  color: red;
  font-size: 1.8rem;
}
.po-edit .el-card__header{
  padding: 0.1rem 1rem;
}
.po-edit div{
  font-size: 1rem;
}
.po-edit .el-card__body {
  padding: 0.2rem;
}
.po-edit .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.el-select .el-input {
  border-color: #409EFF;
}
.el-input.is-disabled .el-input__inner{
  background-color: #e9ecef !important;
  border: 1px solid #cad1d7;
  color: #32325d
}
.err{
  border:1px solid red;
}
.el-table__expand-icon>.el-icon{
  display: none;
}

.el-cascader{
  width: 80%;
}
.el-cascader__tags .el-tag:not(.is-hit){
  font-size: 18px;
}
.el-cascader-menu__list{
  min-width: 250px;
  min-height: 300px;
  max-height: 300px;
  font-size: 18px;
  overflow-x: hidden;
}
.el-checkbox:last-of-type{
  margin-top: 8px;
}
.el-scrollbar__bar.is-vertical{
  width: 0px !important;
  top: 2px !important;
}
#search_modal___BV_modal_body_ > form > section > div > div.el-table__body-wrapper.is-scrolling-none{
  height: 240px !important;
}
.el-table th.gutter{
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>
<style lang="scss" scoped>
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #b3e5fc;
}

</style>